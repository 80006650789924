import React, { ChangeEvent } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  TextField
} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import { deleteProposal } from '@root/services/microsoftAuthService'
import { Proposal } from '@root/generated/graphql-request'
import { useMsal } from '@azure/msal-react'
import { useAppContext } from '@root/global/context'
import { errorToastHelper } from '@root/misc/helpers'
import { useNavigate } from 'react-router-dom'

interface Props {
  children: React.ReactNode
  proposal: Proposal
  onDelete: () => void
  onCancel?: () => void
}

export const DeleteProposalDialog = ({
  proposal,
  children,
  onDelete,
  onCancel
}: Props) => {
  const { instance } = useMsal()
  const [open, setOpen] = React.useState(false)
  const [proposalTitle, setProposalTitle] = React.useState<string>('')
  const [disabled, setDisabled] = React.useState<boolean>(true)
  const { errors, setErrors } = useAppContext()
  const navigate = useNavigate()

  const handleSubmit = (ev: any) => {
    ev.preventDefault()
    deleteProposal(proposal.id, instance)
      .then((_) => {
        onDelete()
        closeDialog()
        navigate('/portaal')
      })
      .catch((_err) => {
        setErrors(
          errorToastHelper(
            errors,
            'Het verwijderen van het voorstel is mislukt. Probeer het opnieuw of neem contact op met de beheerder'
          )
        )
      })
  }

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setProposalTitle(event.target.value)
    if (event.target.value === proposal.title) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
    if (onCancel != null) onCancel()
  }

  return (
    <>
      <div onClick={openDialog}>{children}</div>

      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit
        }}
      >
        <DialogTitle variant='h2'>Voorstel verwijderen</DialogTitle>
        <DialogContent>
          <Box width={488} display='flex' flexDirection='column' gap={2}>
            <DialogContentText>
              Weet je zeker dat je {proposal.title} wilt verwijderen? Dit kan
              niet ongedaan gemaakt worden. Voer hieronder de titel van het
              collectie voorstel in om het verwijderen te bevestigen.
            </DialogContentText>
            <DialogContentText>
              Voer hieronder de titel van het collectie voorstel in om het
              verwijderen te bevestigen.
            </DialogContentText>
            <TextField
              label='Titel voorstel'
              required
              fullWidth
              value={proposalTitle}
              onChange={handleChange}
              variant='outlined'
              sx={{
                '& fieldset': { border: 'none' }
              }}
            />
          </Box>
          <Divider
            sx={{
              color: 'rgba(0, 0, 0, 0.08)',
              marginTop: '24px'
            }}
          />
          <DialogActions>
            <Button
              variant='contained'
              color='neutral'
              onClick={closeDialog}
              sx={{
                bgcolor: 'var(--color-neutral-200)',
                '&.MuiButton-contained': {
                  border: '1px solid rgba(0,0,0,0.04)'
                }
              }}
            >
              Annuleren
            </Button>
            <Button
              variant='contained'
              color='error'
              type='submit'
              disabled={disabled}
              sx={{
                '&.MuiButton-contained': {
                  border: '1px solid rgba(0,0,0,0.08)'
                }
              }}
            >
              Verwijderen
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

import * as React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import CustomerSitesPage from '@root/components/organisms/pages/CustomerSitesPage/Component'
import CustomerSitePage from '@root/components/organisms/pages/CustomerSitePage/Component'
import { LayoutNonAuth } from '@components/templates/non-auth/Component'
import { ConsultantAuth } from './components/templates/consultant-auth/Component'
import { LayoutAuth } from '@components/templates/auth/Component'
import { LoginPage } from '@components/organisms/pages/LoginPage/Component'
import { ProposalOverviewPage } from '@components/organisms/pages/ProposalOverviewPage/Component'
import { ProposalsListPage } from './components/organisms/pages/ProposalsListPage/Component'
import { RedirectPage } from './components/organisms/pages/RedirectPage/Component'
import { ProposalDetailPage } from './components/organisms/pages/ProposalDetailPage/Component'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <LayoutAuth>
        <CustomerSitesPage />
      </LayoutAuth>
    )
  },
  {
    path: '/verhuurorder/:id',
    element: (
      <LayoutAuth>
        <CustomerSitePage />
      </LayoutAuth>
    )
  },
  {
    path: '/portaal',
    element: (
      <ConsultantAuth>
        <ProposalsListPage />
      </ConsultantAuth>
    )
  },
  {
    path: '/voorstellen/:id',
    element: (
      <ConsultantAuth>
        <ProposalOverviewPage />
      </ConsultantAuth>
    )
  },
  {
    path: '/voorstellen/:id/overzicht',
    element: (
      <LayoutAuth>
        <ProposalDetailPage viewer='client' />
      </LayoutAuth>
    )
  },
  {
    path: '/voorstellen/:id/consultant-overzicht',
    element: (
      <ConsultantAuth>
        <ProposalDetailPage viewer='consultant' />
      </ConsultantAuth>
    )
  },
  {
    path: '/login',
    element: (
      <LayoutNonAuth>
        <LoginPage />
      </LayoutNonAuth>
    )
  },
  {
    path: '/redirect',
    element: <RedirectPage />
  },
  {
    path: '*',
    element: <p>Not found</p>
  }
])

const Router = (): JSX.Element => <RouterProvider router={router} />

// function ErrorBoundary(): JSX.Element {
//   const error = useRouteError()
//   console.error(error)
//   // Uncaught ReferenceError: path is not defined
//   return <div>Helaas, deze pagina bestaat helaas niet (meer) of je hebt nog geen toegang tot deze pagina !</div>
// }

export default Router

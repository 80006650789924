import React from 'react'
import style from './style.module.css'
import { Grid } from '@root/components/atoms/Grid/Component'
import { Typography } from '@mui/material'

interface Props {
  title: string
  endElement?: JSX.Element
  titleStyle?: React.CSSProperties
}

export const PageContent = (props: React.PropsWithChildren<Props>): JSX.Element => {
  return (
    <div className={style['page-content']}>
      <Grid>
        <div className={style.title} style={props.titleStyle}>
          <Typography variant='h2'>
            {props.title}
          </Typography>
          {props.endElement}
        </div>
        {props.children}
      </Grid>
    </div>
  )
}

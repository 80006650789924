import React from 'react'
import style from './style.module.css'
import { Grid } from '@root/components/atoms/Grid/Component'
import { Button, Card, CardContent, Typography } from '@mui/material'
import Toll from '@mui/icons-material/Toll'
import ArrowBack from '@mui/icons-material/ArrowBack'
import clsx from 'clsx'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { Painting, Tag } from '@root/components/atoms/Icons/Component'
import EmailIcon from '@mui/icons-material/Email'
import { SwirlComponent } from '@root/components/atoms/Swirl/Component'
import { Account, CustomerSite } from '@root/generated/graphql-request'
import { useNavigate } from 'react-router-dom'
import { formatDecimal, hasValue } from '@root/misc/helpers'

interface SingleLocation {
  type: 'single-location'
  customerSite: CustomerSite
  customerSitesLength: number
  numberOfUnits?: number
  numberOfArtObjects: number
  account: Account
  orderNumber: string
}

interface MultipleLocations {
  type: 'multiple-locations'
  account: Account
  numberOfUnits?: number
  numberOfArtObjects: number
}

interface SingleLocationWithOtherLocations {
  type: 'single-location-with-other-locations'
  customerSite: CustomerSite
  customerSitesLength: number
  numberOfUnits?: number
  numberOfArtObjects: number
  account: Account
}

type Props = (SingleLocation | MultipleLocations | SingleLocationWithOtherLocations)

export const Header = (props: Props): JSX.Element => {
  const isSingleLocationWithoutOtherLocations = props.type === 'single-location'
  const isSingleLocationWithOtherLocations = props.type === 'single-location-with-other-locations'
  const isSingleLocation = isSingleLocationWithoutOtherLocations || isSingleLocationWithOtherLocations
  // const isMultipleLocations = props.type === 'multiple-locations'

  const navigate = useNavigate()

  const getTitle = (): string | undefined => {
    if (props.type === 'single-location' || props.type === 'single-location-with-other-locations') {
      return props.customerSite?.name
    }
    if (props.type === 'multiple-locations') {
      return props.account?.accountName
    }
  }

  const getOrderNumber = (): string => {
    // @ts-expect-error
    return props.orderNumber
  }

  const getAddress = (): string | undefined => {
    if (props.type === 'single-location' || props.type === 'single-location-with-other-locations') {
      return `${props.customerSite.addressLine1} ${hasValue(props.customerSite.addressLine2) ? props.customerSite.addressLine2 : ''}, ${props.customerSite.postalCode}, ${props.customerSite.city}`
    }
    if (props.type === 'multiple-locations') {
      return props.account?.accountName
    }
  }

  const getArtConsultant = (): string => {
    return props.account.ownerFirstName + ' ' + props.account.ownerLastName
  }

  return (
    <header className={style.header}>
      <div className={style.swirl}><SwirlComponent /></div>

      <Grid style={{ rowGap: '24px' }}>
        {
          isSingleLocationWithOtherLocations && (
            <div className={style.back}>
              <Button variant='text' startIcon={<ArrowBack />} className='back-button' onClick={() => navigate('/')}>
                Overzicht
              </Button>
            </div>
          )
        }
        <div className={
          clsx(
            style.wrapper,
            isSingleLocationWithOtherLocations && style['has-back-button']
          )
        }
        >
          <div>
            <Typography variant='h4' sx={{ color: 'var(--color-brand-primary-400)' }}>Welkom,</Typography>
            <Typography variant='h1' sx={{ color: 'var(--color-neutral-100)' }}>{getTitle()}</Typography>

            {
              isSingleLocation && (
                <div className={style.locations}>
                  <div className={style.location}>
                    <Tag height='16px' width='16px' />
                    <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>
                      {getOrderNumber()}
                    </Typography>
                  </div>
                  <div className={style.location}>
                    <LocationOnOutlinedIcon sx={{ fontSize: 16 }} />
                    <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>
                      {getAddress()}
                    </Typography>
                  </div>
                </div>
              )
            }
          </div>
          <div>
            <a href={`mailto:${props.account.ownerEmail}`}>
              <Button variant='contained' color='neutral' className={style['art-consultant']} startIcon={<EmailIcon sx={{ color: 'var(--color-brand-quarternary-300)' }} />}>
                Art Consultant: {getArtConsultant()}
              </Button>
            </a>
          </div>
        </div>
      </Grid>

      <div className={style['cards-wrapper']}>
        <Grid>
          <div className={style.cards}>
            <Card>
              <CardContent>
                <div className={style.icon}>
                  <Painting height='24px' width='24px' />
                </div>
                <div>
                  <Typography variant='h2'>{formatDecimal(props.numberOfArtObjects)}</Typography>
                  <Typography variant='h4' sx={{ color: 'var(--color-neutral-500)' }}>
                    {isSingleLocation ? 'Kunstwerken actief' : 'Totaal aantal kunstwerken actief'}
                  </Typography>
                </div>
              </CardContent>
            </Card>
            {props.numberOfUnits && (
              <Card>
                <CardContent>
                  <div className={style.icon}>
                    <Toll sx={{ color: 'var(--color-neutral-100)' }} />
                  </div>
                  <div>
                    <Typography variant='h2'>{formatDecimal(props.numberOfUnits)}</Typography>
                    <Typography variant='h4' sx={{ color: 'var(--color-neutral-500)' }}>
                      {isSingleLocation ? 'Eenheden actief' : 'Totaal aantal eenheden actief'}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            )}
          </div>
        </Grid>
      </div>

    </header>
  )
}

import { Box, Typography } from '@mui/material'
import { ArtImage } from '@root/components/atoms/ArtImage/Component'
import { ProposalLine } from '@root/generated/graphql-request'
import style from './style.module.css'
import React from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FullscreenIcon from '@mui/icons-material/Fullscreen'

type FullScreenState = 'initial' | 'fullscreen' | 'minimized'

/** A barebones slideshow component specifically made for `ProposalLine`s. */
export const Slideshow = (props: { pls: ProposalLine[] }) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [currentIdx, setCurrentIdx] = React.useState<number>(0)
  const [seenIndices, setSeenIndices] = React.useState<Set<number>>(new Set([0]))

  const slides = props.pls
    .filter((pl) => pl.displayable.mainImagePath !== null)
    .map((pl) => ({ src: pl.displayable.mainImagePath, alt: pl.displayable.title }))
  const slidesLength = slides.length
  const [fullScreenState, setFullScreenState] = React.useState<FullScreenState>('initial')

  const handleMove = (direction: 'left' | 'right') => {
    if (direction === 'left' && currentIdx > 0) {
      setSeenIndices(seenIndices.add(currentIdx))
      setCurrentIdx(currentIdx - 1)
    }
    if (direction === 'right' && currentIdx < slidesLength - 1) {
      setSeenIndices(seenIndices.add(currentIdx))
      setCurrentIdx(currentIdx + 1)
    }
  }

  const handleFullScreenState = (_event: React.MouseEvent): void => {
    switch (fullScreenState) {
      case 'initial':
      case 'minimized':
        setFullScreenState('fullscreen')
        break
      case 'fullscreen':
        setFullScreenState('minimized')
        break
      default:
    }
  }

  const handleFullScreenNavigation = (event: React.KeyboardEvent): void => {
    if (fullScreenState !== 'fullscreen') return
    else if (event.key === 'ArrowLeft') {
      handleMove('left')
    } else if (event.key === 'ArrowRight') handleMove('right')
  }

  React.useEffect(() => {
    switch (fullScreenState) {
      case 'fullscreen':
        if (ref.current !== null) {
          ref.current.requestFullscreen()
        }
        break
      case 'minimized':
        document.exitFullscreen()
        break
      default:
    }
  }, [fullScreenState])

  return (
    <Box
      sx={{
        height: 640,
        maxWidth: '1168px',
        backgroundColor: '#0A1629',
        gridColumn: '2 / -2',
        borderRadius: '12px'
      }}
      ref={ref}
      onKeyUp={handleFullScreenNavigation}
      tabIndex={0}
    >
      <div className={style['displayable-image']}>
        <ArtImage src={slides[currentIdx].src} size='large' alt={slides[currentIdx].alt} />
      </div>
      <div className={style['pills-container']}>
        {slides.map((_s, idx) => {
          const className =
            currentIdx === idx ? `${style.pill} ${style.active}` : `${style.pill} ${seenIndices.has(idx) && style.seen}`

          return <div className={className} key={idx} />
        })}
      </div>
      <div className={style['controls-container']}>
        <div className={style['controls-nav-container']}>
          <ChevronLeftIcon color='warning' onClick={() => handleMove('left')} sx={{ cursor: 'pointer' }} />
          <Typography variant='body1' style={{ color: 'white' }}>
            Kunstwerk {currentIdx + 1} / {slidesLength}
          </Typography>
          <ChevronRightIcon color='warning' onClick={() => handleMove('right')} sx={{ cursor: 'pointer' }} />
        </div>
        <FullscreenIcon color='warning' onClick={handleFullScreenState} sx={{ cursor: 'pointer' }} />
      </div>
    </Box>
  )
}

import { Avatar, Box, Collapse, Typography } from '@mui/material'
import { ArtImage } from '@root/components/atoms/ArtImage/Component'
import { Displayable, Proposal, ProposalLine } from '@root/generated/graphql-request'
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge'

import { artSize, isProposalLine } from '@root/misc/helpers'
import style from './style.module.css'
import { Chevron, Painting } from '@root/components/atoms/Icons/Component'
import { AvailabilityFlag } from '@root/components/atoms/DisplayAvailabilityFlag/Component'
import React from 'react'
import DisplayableDetailView from '../DisplayableDetailView/Component'
import { Viewer } from '@root/misc/types'

/**
 * Depending on the viewer we show different data, as clients get a limited view.
 * But also depending on the title or artist we switch around metadata fields.
 */
export interface ProposalLineDisplayableCardState {
  viewer: 'consultant' | 'client'
  filter: 'customerArtLocation' | 'artist'
}

interface Props {
  pl: ProposalLine
  proposal: Proposal
  state: ProposalLineDisplayableCardState
  displayable: Displayable
  onProposalLinesChange: () => void
  onSelectProposalLine: () => void
}

/** This is a card for Displayables in a ProposalLine, which are shown to clients and consultants. */
export const ProposalLineDisplayableCard = (props: Props): JSX.Element => {
  const [openNote, setOpenNote] = React.useState<boolean>(false)
  return (
    <>
      <div className={style.card}>
        <div className={style['image-wrapper']}>
          <div
            className={style['displayable-image']}
            onClick={() => {
              props.onSelectProposalLine()
            }}
          >
            <ArtImage src={props.pl.displayable.mainImagePath} alt={props.pl.displayable.title} size='medium' />
          </div>
        </div>

        <div className={style.metas}>
          <div>
            <Typography variant='body2'>
              {props.pl.displayable.referenceNumber}
              {props.state.viewer === 'consultant' && (
                <AvailabilityFlag availability={props.pl.displayable.availability} />
              )}
            </Typography>
          </div>
          <Typography variant='h4' fontWeight={500}>
            {props.state.filter == 'customerArtLocation'
              ? props.pl.displayable.mainArtist.artist
              : props.pl.customerArtLocation}
          </Typography>
          <div className={style.meta}>
            <Painting width='24px' height='24px' />
            <Typography variant='body1' sx={{ color: 'var(--color-neutral-600)' }}>
              {props.pl.displayable.title}
            </Typography>
          </div>

          <div className={style.meta}>
            <PhotoSizeSelectLargeIcon height='16px' width='16px' />
            <Typography variant='body1' sx={{ color: 'var(--color-neutral-600)' }}>
              {artSize(props.pl.displayable)}
            </Typography>
          </div>

          {props.pl.note && props.state.viewer === 'consultant' && (
            <div className={style.note}>
              <Box
                sx={{ display: 'flex', gap: 1, alignItems: 'center', color: 'var(--color-neutral-600)', marginTop: 1 }}
                onClick={() => setOpenNote(!openNote)}
              >
                <Avatar
                  src={props.proposal.createdBy.profileImage!}
                  sx={{ height: 16, width: 16, borderRadius: '80px' }}
                />

                <Typography variant='body1'>Opmerking</Typography>
                <Chevron width='16px' height='16px' flipped={!openNote} />
              </Box>

              <Collapse in={openNote} timeout='auto'>
                <Box
                  sx={{
                    backgroundColor: 'var(--color-brand-primary-200)',
                    paddingX: '12px',
                    paddingY: '8px',
                    marginTop: '8px',
                    borderRadius: '2px 12px 12px 12px'
                  }}
                >
                  <Typography variant='body1'>{props.pl.note}</Typography>
                </Box>
              </Collapse>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

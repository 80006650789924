import * as React from 'react'
import { styled, Switch, SwitchProps } from '@mui/material'

export const StandardSwitch = (props: { onClick: () => void, checked: boolean }) => {
  return (
    <Switch
      color='primary'
      checked={props.checked}
      sx={{
        '& .Mui-checked': {
          color: 'var(--color-brand-primary-700)'
        },
        '& .MuiSwitch-track': {
          backgroundColor: 'var(--color-brand-primary-400)'
        }
      }}
      onClick={props.onClick}
    />
  )
}

import { Displayable, ProposalLine } from '@root/generated/graphql-request'
import { Maybe } from 'graphql/jsutils/Maybe'

export interface Error {
  key: string
  message: string
  duration: number
}

/** Add an error message to show in the toast.
 */
export const errorToastHelper = (errors: Error[], message: string, duration: number = 4000): Error[] => {
  return [...errors, { key: new Date().toISOString(), message, duration }]
}

export function hasValue<T> (v: T | undefined | null): v is T {
  return v !== null && v !== undefined
}

export const getIn = (object: any, ...path: any): any => {
  return path.reduce((obj: any, element: any) => obj?.[element], object)
}

/**
 * Create a function that returns its input after calling a handler.
 */
export function doSideEffect<T> (createSideEffect: (thing: T) => any): (thing: T) => T {
  return (thing: T) => {
    createSideEffect(thing)
    return thing
  }
}

/**
 * Create a function that throws its input after calling a handler.
 */
export function doErrorSideEffect<T> (createSideEffect: (thing: T) => any): (thing: T) => T {
  return (thing: T) => {
    createSideEffect(thing)
    throw new Error(thing as string)
  }
}

/**
 * @returns a random UUID
 */

export function createUuid (): string {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

/**
 * Groups items in an array by a specific getter which can be pasased as the second argument.
 * Mimics the Object.groupBy() function for arrays.
 */
export function groupBy<V> (list: V[], keyGetter: (obj: V) => string): Array<{ key: string, value: V[] }> {
  const map = new Map()
  list.forEach((item: V) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })

  return Array.from(map, ([key, value]) => ({ key, value }))
}

/**
 * reverses date format from yyyy-mm-dd to dd-mm-yyyy and vice versa
 */
export function flipDateFormat (date: string): string {
  return date.split('-').reverse().join('-')
}

/**
 * reverses date format from yyyy-mm-dd to dd-mm-yyyy and vice versa
 */
export function formatDateTime (datetime: string | undefined): string {
  if (datetime) {
    const date = datetime.split('T')[0]
    return flipDateFormat(date)
  } else return ''
}

/**
 * Renders a fallback icon when the target value does not exist. Catches any null values that might be returned
 * Takes an optional fallback that will render.
 * Default is set to '?'
 */
export function infoValue (value: string | number | null | undefined, fallback: string = '?'): string | number {
  return hasValue(value) ? value : fallback
}

/**
 * @returns a string from a number, where any dot in a decimal is changed to a comma.
 */
export function formatDecimal (value: number): string {
  return value.toString().replace('.', ',')
}

/**
 * Takes a Displayable as argument.
 * @returns a string displaying the dimentions of the Displayable.
 * If height or width is missing, a questionmark is shown
 * If dept is missing, no depts is shown
 */
export function artSize (displayable: Displayable): string {
  const width = displayable.width ? displayable.width : '?'
  const height = displayable.height ? displayable.height : '?'

  return displayable.depth ? `${height} x ${width} x ${displayable.depth}cm` : `${height} x ${width}cm`
}

/** The default scopes when trying to request tokens through MSAL. */
export const msAuthScopes = {
  read: 'user.read',
  api: 'api://mijn.businessartservice.nl/all'
}

/** Unwraps those pesky Maybes */
export function filterMaybes<T> (arr: Maybe<Array<Maybe<T>>>): T[] {
  return arr != null ? arr.filter((a): a is T => a !== undefined && a !== null) : ([] as T[])
}

interface IsLiked {
  kind: 'isLiked'
  proposalLine: ProposalLine
}

interface NotAdded {
  kind: 'notAdded'
}

/** Indicates if a `displayable` has been added to a `proposal`. */
export type LikedState = IsLiked | NotAdded

/** Checks if the given displayable is in the ProposalLines of a Proposal */
export function isProposalLine (d: Displayable, pl: Maybe<Array<Maybe<ProposalLine>>>): LikedState {
  const proposalLines = filterMaybes(pl)
  const result = proposalLines.find((pl) => d.id === pl.displayable.id)
  if (result !== undefined) {
    return { kind: 'isLiked', proposalLine: result }
  } else return { kind: 'notAdded' }
}

export const customerArtLocation = (pl: ProposalLine): string => pl.customerArtLocation ?? 'Geen ruimte'

export const artist = (pl: ProposalLine): string => pl.displayable.mainArtist.artist ?? 'Onbekend'

export function defineFilterProposalLine (
  filter: 'customerArtLocation' | 'artist'
): (pl: ProposalLine) => string {
  switch (filter) {
    case 'customerArtLocation':
      return customerArtLocation
    case 'artist':
      return artist
  }
}

import React from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  TextField
} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import { useMsal } from '@azure/msal-react'
import {
  Maybe,
  Proposal,
  SuggestedCustomerArtLocation
} from '@root/generated/graphql-request'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { createProposalLine } from '@root/services/microsoftAuthService'
import { errorToastHelper } from '@root/misc/helpers'
import { useAppContext } from '@root/global/context'
import { ZohoIcon } from '../Icons/Component'

interface Props {
  proposal: Proposal
  displayableId: string
  /** The content to show when the Displayable hasn't been added to the Proposal  */
  children: React.ReactNode
  onChange: () => void
}

const charCounter = (input: string | null) => typeof input === 'string' ? 255 - input.length : 255

export const ProposalLineDialog = ({
  proposal,
  displayableId,
  onChange: onLike,
  children
}: Props) => {
  const { instance } = useMsal()
  const [open, setOpen] = React.useState(false)
  const [selectedCustomerSite, setSelectedCustomerSite] =
    React.useState<string>('')
  const [customerSiteError, setCustomerSiteError] =
    React.useState<boolean>(false)
  const [note, setNote] = React.useState<string | null>(null)
  const { errors, setErrors } = useAppContext()

  const handleCustomerArtLocationChange = (
    value: string | Maybe<SuggestedCustomerArtLocation>
  ): void => {
    setCustomerSiteError(false)
    if (typeof value === 'string') {
      setSelectedCustomerSite(value)
    } else {
      setSelectedCustomerSite(value?.customerArtLocation ?? '')
    }
  }

  const handleSubmit = (ev: any) => {
    ev.preventDefault()
    if (
      selectedCustomerSite.match(/^([^-]*)-([^-]*)-([^-]*)-([^-]*)$/) === null
    ) {
      setCustomerSiteError(true)
    } else {
      createProposalLine(
        {
          displayableId,
          proposalId: proposal.id,
          customerArtLocation: selectedCustomerSite,
          note
        },
        instance
      )
        .then((_) => {
          onLike()
          closeDialog()
        })
        .catch((_err) => {
          setErrors(
            errorToastHelper(
              errors,
              'Het aanmaken van het voorstel is mislukt. Probeer het opnieuw of neem contact op met de beheerder.'
            )
          )
        })
    }
  }

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <>
      <div onClick={openDialog} className='add-to-proposal-line-slot'>
        {children}
      </div>
      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit
        }}
      >
        <DialogTitle variant='h2'>
          Kies een ruimte en voeg een opmerking toe
        </DialogTitle>

        <DialogContent>
          <Box width={488} display='flex' flexDirection='column' gap={2}>
            <DialogContentText>
              Kies een ruimte waar het kunstwerk aan gekoppeld gaat worden en
              schrijf een optionele opmerking. Als de ruimte nog niet bestaat
              kan deze direct aangemaakt worden.
            </DialogContentText>
            <FormControl fullWidth>
              <Autocomplete
                freeSolo
                popupIcon={<ExpandMoreIcon />}
                onChange={(_ev, val) => {
                  handleCustomerArtLocationChange(val)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Ruimte'
                    placeholder='A - 01 - 001 - Naam ruimte'
                    required
                    value={selectedCustomerSite}
                    error={customerSiteError}
                    onChange={(ev) => {
                      handleCustomerArtLocationChange(ev.target.value)
                    }}
                    helperText='Vul in het formaat in: A - 01 - 001 - Naam ruimte'
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
                id='customer-site-question'
                options={proposal.suggestedCustomerArtLocations ?? []}
                getOptionLabel={(ca) => {
                  if (typeof ca === 'string') {
                    return ca
                  } else {
                    return ca?.customerArtLocation ?? ''
                  }
                }}
                renderOption={(props, ca: unknown) => {
                  const isString: boolean = typeof ca === 'string'
                  return isString
                    ? (
                      <li {...props}>{ca as string}</li>
                      )
                    : (
                      <li
                        {...props} style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span>
                          {(ca as SuggestedCustomerArtLocation)
                            ?.customerArtLocation ?? ''}
                        </span>
                        {(ca as SuggestedCustomerArtLocation)
                          .existsInBackOffice
                          ? (
                            <ZohoIcon />
                            )
                          : (
                            <></>
                            )}
                      </li>
                      )
                }}
                sx={{
                  '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                    color: 'var(--color-brand-quarternary-300)',
                    width: 24,
                    height: 24
                  }
                }}
              />
            </FormControl>

            <TextField
              label='Opmerking'
              fullWidth
              variant='outlined'
              value={note}
              multiline
              onChange={(ev) => setNote(ev.target.value)}
              helperText={`${charCounter(note)} char`}
              inputProps={{
                maxLength: 255
              }}
              sx={{
                '& fieldset': { border: 'none' }
              }}
            />
          </Box>
          <Divider
            sx={{
              color: 'rgba(0, 0, 0, 0.08)',
              marginTop: '24px'
            }}
          />
          <DialogActions>
            <Button
              variant='contained'
              color='neutral'
              onClick={closeDialog}
              sx={{
                bgcolor: 'var(--color-neutral-200)',
                '&.MuiButton-contained': {
                  border: '1px solid rgba(0,0,0,0.04)'
                }
              }}
            >
              Annuleren
            </Button>
            <Button
              variant='contained'
              type='submit'
              sx={{
                '&.MuiButton-contained': {
                  border: '1px solid rgba(0,0,0,0.08)'
                }
              }}
            >
              Toevoegen
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

import React from 'react'
import style from './style.module.css'
import { Typography } from '@mui/material'
import Toll from '@mui/icons-material/Toll'
import { Painting } from '@root/components/atoms/Icons/Component'
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge'
import { formatDecimal, artSize } from '@root/misc/helpers'
import { Placement, RentalOrderLine } from '@root/generated/graphql-request'
import { ArtImage } from '@root/components/atoms/ArtImage/Component'

interface Props {
  rentalOrders: RentalOrderLine[]
  description: string
  setActiveArtObject: (artObj: Placement) => void
}

export const RentalOrderLineCard = (props: Props): JSX.Element => {
  return (
    <div className={style.card}>
      <Typography variant='h3' sx={{ marginBlockEnd: '16px' }}>
        {props.description}
      </Typography>

      <div className={style.grid}>
        {
          props.rentalOrders.map(({ placement }) => {
            return (
              <React.Fragment key={placement.id}>
                <div>
                  <div className={style['image-wrapper']} onClick={() => props.setActiveArtObject(placement)}>
                    <ArtImage src={placement.artObject.displayable.mainImagePath} size='medium' alt={placement.artObject.title} />
                  </div>

                  <div className={style.metas}>
                    <div className={style.meta}>
                      <Typography variant='body2' sx={{ color: 'var(--color-neutral-600)' }}>
                        {placement.artObject.articleNumber}
                      </Typography>
                    </div>

                    <div className={style.meta}>
                      <Typography variant='h4' sx={{ color: 'var(--color-neutral-900)' }}>{placement.artObject.displayable.mainArtist.artist}</Typography>
                    </div>

                    <div className={style.meta}>
                      <div className={style.icon}>
                        <Painting height='16px' width='16px' />
                      </div>
                      <Typography variant='body2' sx={{ color: 'var(--color-neutral-600)' }}>
                        {placement.artObject.title}
                      </Typography>
                    </div>

                    <div className={style.meta}>
                      <div className={style.icon}>
                        <PhotoSizeSelectLargeIcon sx={{ fontSize: 16 }} />
                      </div>
                      <Typography variant='body2' sx={{ color: 'var(--color-neutral-600)' }}>
                        {artSize(placement.artObject.displayable)}
                      </Typography>
                    </div>

                    <div className={style.meta}>
                      <div className={style.icon}>
                        <Toll sx={{ fontSize: 16 }} />
                      </div>
                      <Typography variant='body2' sx={{ color: 'var(--color-neutral-600)' }}>
                        {formatDecimal(placement.numberOfUnits)} eenheden
                      </Typography>
                    </div>
                  </div>

                </div>
              </React.Fragment>
            )
          })
        }
      </div>
    </div>
  )
}

import { getWebsiteApiUrl, getWebsiteRestApiUrl } from '@misc/environments'
import { createGraphqlRequestSdk } from '@misc/graphql-request-sdk'
import { TokenService, Tokens, subscribeToPeerTabUpdates, RefreshCallback } from '@weareyipyip/multitab-token-refresh'
import Axios from 'axios'
import {
  Account,
  AccountsQuery,
  ArtObject,
  ArtObjectTeaserFragment,
  Displayable,
  DisplayablesQuery,
  DisplayableTeaserFragment,
  GetProposalsQuery,
  MyProposalsQuery,
  Proposal,
  RentalOrder,
  RentalOrdersQuery
} from '@generated/graphql-request'
import { hasValue } from '@root/misc/helpers'

const REFRESH_TOKEN_EXP = 86400 * 365 * 10 // 10 years

const restEndpoint = getWebsiteRestApiUrl()
const endpoint = getWebsiteApiUrl()

const sdk = createGraphqlRequestSdk(new URL(endpoint))

// type PropertiesHasValue<T> = {
//   [P in keyof T]-?: NonNullable<T[P]>
// }

// type HasValue<T, P extends keyof T> = T & PropertiesHasValue<Pick<T, P>>

// export type BigIntID = Scalars['BigIntID']

// /// //////////
// // Private //
// /// /////////

function raiseWhenFalsy<T> (response: T, predicate: (response: T) => any): void {
  // eslint-disable-next-line
  if (!predicate(response)) throw response
}

function loginResponseToTokens (resp: any): Tokens {
  if (
    resp?.access_token !== null &&
    resp?.access_token !== undefined &&
    resp?.refresh_token !== null &&
    resp?.refresh_token !== undefined
  ) {
    const { access_token, refresh_token, expires_in } = resp
    return {
      accessToken: access_token,
      refreshToken: refresh_token,
      accessTokenExp: expireFormat(new Date(), expires_in),
      refreshTokenExp: expireFormat(new Date(), REFRESH_TOKEN_EXP)
    }
  } else throw new Error('unexpected auth response')
}

function refreshResponseToTokens (resp: any, refreshToken: any): any {
  if (resp?.access_token !== null && resp?.access_token !== undefined) {
    const { access_token, expires_in } = resp
    return {
      accessToken: access_token,
      accessTokenExp: expireFormat(new Date(), expires_in),
      refreshToken,
      refreshTokenExp: expireFormat(new Date(), REFRESH_TOKEN_EXP)
    }
  } else throw new Error('unexpected auth response')
}

function expireFormat (date: Date, seconds: number) {
  return Math.floor(date.getTime() / 1000 + seconds)
}

// /// //////
// // AUTH //
// /// //////

/**
 * Refresh tokens. The tokens
 *
 * @param {string} refreshToken
 * @returns a promise with account and session
 */
const refreshCurrentSession: RefreshCallback = async (refreshToken: string, setLoggedOut) => {
  try {
    const response = await Axios.post(
      restEndpoint + '/auth/token',
      {
        refresh_token: refreshToken,
        grant_type: 'refresh_token'
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )

    if (response?.status === 401) setLoggedOut()

    raiseWhenFalsy(response, (r) => r.status === 200)

    return refreshResponseToTokens(response.data, refreshToken)
  } catch (error) {
    setLoggedOut()
    throw error
  }
}

// set refreshcallback for TokenService
const tokenService = new TokenService(refreshCurrentSession)
subscribeToPeerTabUpdates(tokenService)

/**
 * Login to the API. The tokens are removed from the return value,
 * because they are managed by and should be requested from TokenService.
 *
 * @param {string} code
 * @returns a promise with account and session
 */
async function login (code: string): Promise<any> {
  try {
    const loginResponse = await Axios.post(
      restEndpoint + '/auth/login/secret-code',
      { code },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )

    raiseWhenFalsy(loginResponse, (r) => r.status === 200)

    tokenService.setStatus(loginResponseToTokens(loginResponse.data))

    return loginResponse.data
  } catch (response) {
    tokenService.setLoggedOut()
    throw response
  }
}

/**
 * Delete current session
 *
 * @returns a promise with account and session
 */
async function logout (): Promise<any> {
  try {
    const token = await tokenService.getAccessToken()

    const logoutResponse = await Axios.post(
      restEndpoint + '/auth/logout',
      {},
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`
        }
      }
    )

    raiseWhenFalsy(logoutResponse, (r) => r.status === 200)

    return logoutResponse.data
  } finally {
    tokenService.setLoggedOut()
  }
}

/**
 * Get Displayable by ID
 * @returns The displayable.
 */
async function getDisplayable (id: string): Promise<Displayable> {
  const token = await tokenService.getAccessToken()
  const response = await sdk.GetDisplayable(
    { id },
    { Authorization: `Bearer ${token}` }
  )

  raiseWhenFalsy(response, (r) => hasValue(r.displayable))
  return response.displayable as Displayable
}

async function getDisplayables (): Promise<ListDisplayables> {
  const response = await sdk
    .Displayables
    // { Authorization: `Bearer ${token}` }
    ()

  raiseWhenFalsy(response, (r) => Array.isArray(r.displayables))
  return response.displayables
}

// /**
//  * Get Displayable by ID
//  * @returns The displayable.
//  */
async function getDisplayableTeaser (id: string): Promise<DisplayableTeaserFragment> {
  // const token = await tokenService.getAccessToken()
  const response = await sdk.GetDisplayableTeaser(
    { id }
    // { Authorization: `Bearer ${token}` }
  )

  raiseWhenFalsy(response, (r) => hasValue(r.displayable))
  return response.displayable as DisplayableTeaserFragment
}

// /**
//  * Get Art Object by ID
//  * @returns The artObject.
//  */
async function getArtObject (id: string): Promise<ArtObject> {
  // const token = await tokenService.getAccessToken()
  const response = await sdk.GetArtObject(
    { id }
    // { Authorization: `Bearer ${token}` }
  )

  raiseWhenFalsy(response, (r) => hasValue(r.artObject))
  return response.artObject as ArtObject
}

// /**
//  * Get Art Object by ID
//  * @returns The artObject.
//  */
async function getArtObjectTeaser (id: string): Promise<ArtObjectTeaserFragment> {
  // const token = await tokenService.getAccessToken()
  const response = await sdk.GetArtObjectTeaser(
    { id }
    // { Authorization: `Bearer ${token}` }
  )

  raiseWhenFalsy(response, (r) => hasValue(r.artObject))
  return response.artObject as ArtObjectTeaserFragment
}

// /**
//  * Get rentalOrder by ID
//  * @returns The rentalOrder.
//  */
async function getRentalOrder (id: string, filter: { artist: string, searchTerm: string }): Promise<RentalOrder> {
  const token = await tokenService.getAccessToken()

  const response = await sdk.rentalOrder({ id, filter }, { Authorization: `Bearer ${token}` })

  raiseWhenFalsy(response, (r) => hasValue(r.rentalOrder))
  return response.rentalOrder as RentalOrder
}

// /**
//  * List rental orders
//  * @returns The rentalOrders.
//  */
export type ListRentalOrders = RentalOrdersQuery['myRentalOrders']
export type ListProposals = MyProposalsQuery['myProposals']

export type ListDisplayables = DisplayablesQuery['displayables']

async function listMyRentalOrders (): Promise<ListRentalOrders> {
  const token = await tokenService.getAccessToken()
  const response = await sdk.rentalOrders({}, { Authorization: `Bearer ${token}` })

  raiseWhenFalsy(response, (r) => Array.isArray(r.myRentalOrders))
  return response.myRentalOrders
}

async function listMyProposals (): Promise<ListProposals> {
  const token = await tokenService.getAccessToken()
  const response = await sdk.myProposals({}, { Authorization: `Bearer ${token}` })

  raiseWhenFalsy(response, (r) => Array.isArray(r.myProposals))
  return response.myProposals
}

async function getProposal (id: string): Promise<Proposal> {
  const token = await tokenService.getAccessToken()
  const response = await sdk.GetProposal({ id }, { Authorization: `Bearer ${token}` })

  raiseWhenFalsy(response, (r) => hasValue(r))
  return response.proposal as Proposal
}

// /**
//  * Lists only the ID and ordernumber of the rentalOrders
//  * @returns The rentalOrders.
//  */
export type RentalOrdersTeaser = RentalOrdersQuery['myRentalOrders']

async function listRentalOrdersTeaser (): Promise<RentalOrdersTeaser> {
  const token = await tokenService.getAccessToken()
  const response = await sdk.rentalOrders({}, { Authorization: `Bearer ${token}` })

  raiseWhenFalsy(response, (r) => Array.isArray(r.myRentalOrders))
  return response.myRentalOrders
}

// /**
//  * List Accounts
//  * @returns The accounts.
//  */
export type ListAccounts = AccountsQuery['accounts']

async function listAccounts (): Promise<AccountsQuery['accounts']> {
  // const token = await tokenService.getAccessToken()
  const response = await sdk
    .Accounts
    // { Authorization: `Bearer ${token}` }
    ()

  raiseWhenFalsy(response, (r) => Array.isArray(r.accounts))
  return response.accounts
}

/**
 * Get Account by ID
 * @returns The account.
 */
// async function getAccount (id: string): Promise<Account> {
//   // const token = await tokenService.getAccessToken()
//   const response = await sdk.GetAccount(
//     { id }
//     // { Authorization: `Bearer ${token}` }
//   )

//   raiseWhenFalsy(response, (r) => hasValue(r.account))
//   return response.account as Account
// }

export {
  getDisplayable,
  getDisplayableTeaser,
  getArtObject,
  getArtObjectTeaser,
  listAccounts,
  listMyRentalOrders,
  listRentalOrdersTeaser,
  getRentalOrder,
  listMyProposals,
  getProposal,
  getDisplayables,
  // getAccount,
  login,
  logout,
  tokenService
}

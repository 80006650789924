import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Toll from '@mui/icons-material/Toll'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import { Box, Button, Card, CardContent, FormControlLabel, InputAdornment, TextField, Tooltip, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { Grid } from '@root/components/atoms/Grid/Component'
import { BuildingIcon, ImagePlusIcon, Painting, PencilIcon, PersonIcon } from '@root/components/atoms/Icons/Component'
import { ProposalQuickMenu } from '@root/components/atoms/ProposalQuickMenu/Component'
import { SwirlComponent } from '@root/components/atoms/Swirl/Component'
import { Proposal, UserProfile } from '@root/generated/graphql-request'
import { errorToastHelper, formatDateTime, formatDecimal, hasValue } from '@root/misc/helpers'
import React from 'react'
import { redirect, useNavigate } from 'react-router-dom'
import style from './style.module.css'
import { StandardSwitch } from '@root/components/atoms/StandardSwitch/Component'
import { createSecretCode, patchSecretCode } from '@root/services/microsoftAuthService'
import { useMsal } from '@azure/msal-react'
import { Viewer } from '@root/misc/types'
import Error from '@mui/icons-material/Error'
import { useAppContext } from '@root/global/context'

interface ProposalsListHeaderProps {
  userProfile: UserProfile
}

interface ProposalOverviewHeaderProps {
  proposal: Proposal
}

interface ProposalDetailHeaderProps {
  proposal: Proposal
  state: Viewer
  onSecretLinkShare: () => Promise<void>
}

interface ProposalsListHeaderState {
  kind: 'proposals'
  props: ProposalsListHeaderProps
}

interface ProposalDetailHeaderState {
  kind: 'proposal-detail'
  props: ProposalDetailHeaderProps
}

interface ProposalOverviewHeaderState {
  kind: 'proposal-overview'
  props: ProposalOverviewHeaderProps
}

interface ConsultantShareCardProps {
  proposal: Proposal, onSecretLinkShare: () => Promise<void>
}

export type HeaderState = ProposalsListHeaderState | ProposalOverviewHeaderState | ProposalDetailHeaderState

interface HeaderProps {
  /** Determines what content to show in the header. */
  state: HeaderState
}

const ConsultantShareCard = (props: ConsultantShareCardProps): JSX.Element => {
  const [isShared, setIsShared] = React.useState(props.proposal.secretCode?.isActive ?? false)
  const [shareLink, setShareLink] = React.useState<string | null>(null)
  const { instance } = useMsal()
  const { errors, setErrors } = useAppContext()
  const [isCopied, setIsCopied] = React.useState<boolean>(false)

  React.useEffect(() => {
    if ((props.proposal.secretCode != null) && props.proposal.secretCode.isActive) {
      setShareLink(window.location.origin + `/?c=${props.proposal.secretCode.secretCode}`)
      setIsShared(true)
    }
  }, [])

  /** Update the state of the secret code and share link after toggling the share.  */
  React.useEffect(() => {
    if (isShared && (props.proposal.secretCode == null)) {
      createSecretCode(instance, { resources: [{ resourceId: props.proposal.id, resourceType: 'proposal' }] })
        .then((_res) => {
          props.onSecretLinkShare().then(_ => {
            setIsCopied(true)
            setTimeout(() => {
              setIsCopied(false)
            }, 2000)
          })
        })
        .catch((_err) => {
          setErrors(errorToastHelper(
            errors,
            'Het aanmaken van de link is mislukt. Probeer het opnieuw of neem contact op met de beheerder.'
          ))
          setIsShared(false)
        })
    } else if (isShared && !props.proposal.secretCode?.isActive) {
      patchSecretCode(instance, props.proposal.secretCode!.id, { isActive: true }).then((_resp) => {
        props.onSecretLinkShare()
        setIsShared(true)
      })
        .catch((_err) => {
          setErrors(errorToastHelper(
            errors,
            'Het veranderen van de link is mislukt. Probeer het opnieuw of neem contact op met de beheerder.'
          ))
          setIsShared(false)
        })
    } else if (!isShared && props.proposal.secretCode?.isActive) {
      patchSecretCode(instance, props.proposal.secretCode.id, { isActive: false }).then((_resp) => {
        props.onSecretLinkShare()
      }).catch((_err) => {
        setErrors(errorToastHelper(
          errors,
          'Het veranderen van de link is mislukt. Probeer het opnieuw of neem contact op met de beheerder.'
        ))
        setIsShared(false)
      })
    } else if (!isShared && hasValue(shareLink)) {
      setShareLink(null)
      setIsCopied(false)
    }
  }, [isShared])

  React.useEffect(() => {
    if (shareLink && isCopied) {
      navigator.clipboard.writeText(shareLink)
    }
  }, [isCopied])

  return (
    <Card sx={{ maxHeight: '102px' }}>
      <CardContent>
        <div className={style.icon}>
          <Painting height='24px' width='24px' />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant='h4' sx={{ color: 'var(--color-neutral-500)' }}>
            Voorstel delen
          </Typography>
          <Box>
            <FormControlLabel
              control={<StandardSwitch checked={shareLink !== null} onClick={() => setIsShared(!isShared)} />}
              label={shareLink ? 'Aan' : 'Uit'}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginX: 0
              }}
            />
          </Box>
        </div>
        {shareLink && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
              id='share-link'
              value={shareLink}
              variant='outlined'
              size='small'
              sx={{
                '& fieldset': { border: 'none' },
                width: '273px',
                '& .MuiInputBase-input': {
                  maxHeight: 40
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <InsertLinkIcon />
                  </InputAdornment>
                )
              }}
            />
            <Tooltip open={isCopied} onClose={() => setIsCopied(false)} title='Gekopieerd' enterDelay={500} leaveDelay={200}><Button
              variant='contained'
              type='submit'
              color='warning'
              sx={{
                '&.MuiButton-contained': {
                  border: '1px solid rgba(0,0,0,0.08)'
                }
              }}
              onClick={() => setIsCopied(true)}
                                                                                                                             >
              Kopiëren
            </Button>
            </Tooltip>
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

export const ProposalOverviewHeader = (props: ProposalOverviewHeaderProps): JSX.Element => {
  const [proposal, setProposal] = React.useState<Proposal>(props.proposal)
  const navigate = useNavigate()
  return (
    <>
      <div>
        <div className={style['go-back-container']} onClick={() => navigate('/portaal')}>
          <ArrowBackIcon />
          <Typography variant='button'>Terug</Typography>
        </div>
        <Typography
          variant='h4'
          sx={{
            color: 'var(--color-brand-primary-400)',
            fontWeight: 400
          }}
        >
          Voorstel
        </Typography>
        <div className={style['proposal-title-container']}>
          <Typography variant='h1' sx={{ color: 'var(--color-neutral-100)' }}>
            {proposal.title}
          </Typography>
          <ProposalQuickMenu
            proposal={proposal}
            iconColor='rgba(161, 187, 232, 1)'
            onEditSuccess={(p) => {
              setProposal(p)
            }}
            onDelete={() => redirect('/portaal')}
          />
        </div>
        <div className={style['header-metadata']}>
          <div className={style['metadata-item']}>
            <PersonIcon height='16px' width='16px' />
            <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>
              {proposal.account.ownerFirstName} {proposal.account.ownerLastName}
            </Typography>
          </div>
          <div className={style['metadata-item']}>
            <BuildingIcon height='16px' width='16px' />
            <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>
              {proposal.customerSite.name}
            </Typography>
          </div>
          <div className={style['metadata-item']}>
            <ImagePlusIcon height='16px' width='16px' />
            <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>
              {formatDateTime(proposal.createdAt)}
            </Typography>
          </div>
          <div className={style['metadata-item']}>
            <PencilIcon height='16px' width='16px' />
            <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>
              {formatDateTime(proposal.updatedAt)}
            </Typography>
          </div>
          {proposal.reserveArtObjectsUntil && (
            <div className={style['metadata-item']}>
              <Error sx={{ height: '16px', width: '16px' }} />
              <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>
                Gereserveerd t/m  {formatDateTime(proposal.reserveArtObjectsUntil)}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div>
        <Button
          variant='contained'
          color='neutral'
          className={style['art-consultant']}
          startIcon={
            <div style={{ color: 'var(--color-brand-quarternary-300)' }}>
              <Painting width='24' height='24' />
            </div>
          }
          onClick={() => navigate(`/voorstellen/${proposal.id}/consultant-overzicht`)}
        >
          Bekijk voorstel
        </Button>
      </div>
    </>
  )
}

export const ProposalsListHeader = (props: ProposalsListHeaderProps) => {
  return (
    <div className={style['art-consultant-container']}>
      <Avatar src={props.userProfile.profileImage!} sx={{ height: 72, width: 72 }} />
      <div>
        <Typography
          variant='h4'
          sx={{
            color: 'var(--color-brand-primary-400)',
            fontWeight: 400
          }}
        >
          Welkom,
        </Typography>
        <Typography variant='h1' sx={{ color: 'var(--color-neutral-100)' }}>
          {props.userProfile.fullName}
        </Typography>
      </div>
    </div>
  )
}

export const ProposalDetailHeader = ({ proposal, state, onSecretLinkShare }: ProposalDetailHeaderProps) => {
  const navigate = useNavigate()

  return (
    <>
      <div>
        {state === 'consultant' && (
          <div
            className={style['go-back-container']}
            onClick={() => {
              navigate(`/voorstellen/${proposal.id}`)
            }}
          >
            <ArrowBackIcon />
            <Typography variant='button'>Bewerken</Typography>
          </div>
        )}
        <Typography
          variant='h4'
          sx={{
            color: 'var(--color-brand-primary-400)',
            fontWeight: 400
          }}
        >
          Voorstel overzicht
        </Typography>
        <div className={style['proposal-title-container']}>
          <Typography variant='h1' sx={{ color: 'var(--color-neutral-100)' }}>
            {proposal.title}
          </Typography>
        </div>
        <div className={style['header-metadata']}>
          <div className={style['metadata-item']}>
            <PersonIcon height='16px' width='16px' />
            <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>
              {proposal.account.ownerFirstName} {proposal.account.ownerLastName}
            </Typography>
          </div>
          <div className={style['metadata-item']}>
            <BuildingIcon height='16px' width='16px' />
            <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>
              {proposal.customerSite.name}
            </Typography>
          </div>
          <div className={style['metadata-item']}>
            <ImagePlusIcon height='16px' width='16px' />
            <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>
              {formatDateTime(proposal.createdAt)}
            </Typography>
          </div>
          <div className={style['metadata-item']}>
            <PencilIcon height='16px' width='16px' />
            <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>
              {formatDateTime(proposal.updatedAt)}
            </Typography>
          </div>
          {proposal.reserveArtObjectsUntil && (
            <div className={style['metadata-item']}>
              <Error sx={{ height: '16px', width: '16px' }} />
              <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>
                Gereserveerd t/m {formatDateTime(proposal.reserveArtObjectsUntil)}
              </Typography>
            </div>
          )}
        </div>
        <div className={style['cards-wrapper']}>
          <Grid>
            <div className={style.cards}>
              <Card>
                <CardContent>
                  <div className={style.icon}>
                    <Toll sx={{ color: 'var(--color-neutral-100)' }} />
                  </div>
                  <div>
                    <Typography variant='h2'>{formatDecimal(proposal.proposalLines?.length ?? 0)}</Typography>
                    <Typography variant='h4' sx={{ color: 'var(--color-neutral-500)' }}>
                      Kunstwerken geselecteerd
                    </Typography>
                  </div>
                </CardContent>
              </Card>
              {state === 'consultant' && <ConsultantShareCard proposal={proposal} onSecretLinkShare={onSecretLinkShare} />}
            </div>
          </Grid>
        </div>
      </div>
    </>
  )
}

export const ProposalHeader = ({ state }: HeaderProps) => {
  const HeaderContent = () => {
    switch (state.kind) {
      case 'proposal-overview':
        return <ProposalOverviewHeader proposal={state.props.proposal} />
      case 'proposals':
        return <ProposalsListHeader userProfile={state.props.userProfile} />
      case 'proposal-detail':
        return <ProposalDetailHeader proposal={state.props.proposal} state={state.props.state} onSecretLinkShare={state.props.onSecretLinkShare} />
    }
  }

  return (
    <header className={style.header}>
      <div className={style.swirl}>
        <SwirlComponent />
      </div>
      <Grid style={{ rowGap: '24px' }}>
        <div className={style.wrapper}>
          <HeaderContent />
        </div>
      </Grid>
    </header>
  )
}

import React from 'react'
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  TextField
} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { createProposal, listAccountsWithCustomerSites, patchProposal } from '@root/services/microsoftAuthService'
import { Account, CustomerSite, Proposal } from '@root/generated/graphql-request'
import { useMsal } from '@azure/msal-react'
import { useAppContext } from '@root/global/context'
import { errorToastHelper } from '@root/misc/helpers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/nl'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider'
import moment, { Moment } from 'moment'
import { DateValidationError } from '@mui/x-date-pickers/models/validation'

interface Props {
  children: React.ReactNode
  proposal?: Proposal
  /* Callback to fire off when the form has been validated and submitted. */
  onSuccess: (proposal: Proposal) => void
  onCancel?: () => void
}

export const ProposalDialog = ({ proposal, children, onSuccess, onCancel }: Props) => {
  const { instance } = useMsal()
  const [open, setOpen] = React.useState(false)
  const [proposalTitle, setProposalTitle] = React.useState<string>(proposal != null ? proposal.title : '')
  const [isFetchingAccounts, setIsFetchingAccounts] = React.useState<boolean>(false)
  const [accounts, setAccounts] = React.useState<Account[]>([])
  const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(
    proposal != null ? proposal.account : null
  )
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const [selectedCustomerSite, setSelectedCustomerSite] = React.useState<CustomerSite | null>(
    proposal != null ? proposal.customerSite : null
  )
  const [reserveUntil, setReserveUntil] = React.useState<Moment | null>(
    ((proposal != null) && proposal?.reserveArtObjectsUntil !== null) ? moment(proposal?.reserveArtObjectsUntil) : null
  )

  const inThreeMonths = moment().add(90, 'days')
  const [reservationError, setReservationError] = React.useState<DateValidationError | null>(null)
  const { errors, setErrors } = useAppContext()
  const reservationErrorMessage = React.useMemo(() => {
    switch (reservationError) {
      case 'maxDate': {
        return 'De datum mag niet meer dan 90 dagen in de toekomst liggen.'
      }
      case 'disablePast': {
        return 'Datum moet in de toekomst zijn'
      }
      default: {
        return ''
      }
    }
  }, [reservationError])
  const fetchAccounts = React.useCallback(
    async (searchTerm?: string) => {
      const accounts = await listAccountsWithCustomerSites(instance, searchTerm)

      if (accounts == null) {
        setAccounts([])
      } else setAccounts(accounts as Account[])
    },
    [accounts]
  )

  React.useEffect(() => {
    setIsFetchingAccounts(true)
    if (open) {
      try {
        fetchAccounts()
      } catch (e) {
        setErrors(
          errorToastHelper(
            errors,
            'Het inladen van het voorstel is mislukt. Ververs de pagina of neem contact op met de beheerder.'
          )
        )
      }
    }
    setIsFetchingAccounts(false)
  }, [open])

  React.useEffect(() => {
    const debounceFn = setTimeout(() => {
      setIsFetchingAccounts(true)
      fetchAccounts(searchTerm)
        .then(() => {
          setIsFetchingAccounts(false)
        })
        .catch((err) => {
          setErrors(
            errorToastHelper(
              errors,
              'Het inladen van het voorstel is mislukt. Ververs de pagina of neem contact op met de beheerder.'
            )
          )
        })
    }, 500)

    return () => clearTimeout(debounceFn)
  }, [searchTerm])

  const handleSubmit = (ev: any) => {
    ev.preventDefault()
    if (proposal != null) {
      patchProposal(
        proposal.id,
        {
          accountId: selectedAccount?.id,
          customerSiteId: selectedCustomerSite?.id,
          title: proposalTitle,
          reserveArtObjectsUntil: (reserveUntil != null) ? reserveUntil?.format('YYYY-MM-DD') : null
        },
        instance
      )
        .then((p) => {
          onSuccess(p)
          closeDialog()
        })
        .catch((err) => {
          setErrors(
            errorToastHelper(
              errors,
              'Het aanpassen van het voorstel is mislukt. Probeer het opnieuw of neem contact op met de beheerder.'
            )
          )
        })
    } else {
      if (selectedAccount != null && selectedCustomerSite != null && proposalTitle) {
        createProposal(
          {
            accountId: selectedAccount?.id,
            customerSiteId: selectedCustomerSite?.id,
            title: proposalTitle,
            reserveArtObjectsUntil: (reserveUntil != null) ? reserveUntil?.format('YYYY-MM-DD') : null
          },
          instance
        )
          .then((p) => {
            onSuccess(p)
            closeDialog()
          })
          .catch((err) => {
            setErrors(
              errorToastHelper(
                errors,
                'Het aanmaken van het voorstel is mislukt. Probeer het opnieuw of neem contact op met de beheerder.'
              )
            )
          })
      }
    }
  }

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
    if (onCancel != null) onCancel()
  }

  const selectedAccountOnChange = (val: Account | null, reason: AutocompleteChangeReason) => {
    if (reason === 'removeOption') {
      setSelectedAccount(null)
    } else if (val != null) {
      setSelectedAccount(val)
    }

    setSelectedCustomerSite(null)
  }

  /** In MUI 5 pressing Tab triggers a close of the parent menu, which causes this dialog to be unmounted. Since we don't want that, we add this handler. */
  const stopPropagationForTab = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  return (
    <>
      <div onClick={openDialog}>{children}</div>

      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit
        }}
        onKeyDown={stopPropagationForTab}
      >
        <DialogTitle variant='h2'>{proposal != null ? 'Wijzig voorstel' : 'Start nieuw voorstel'}</DialogTitle>
        <DialogContent>
          <Box width={488} display='flex' flexDirection='column' gap={2} marginTop={1}>
            <TextField
              label='Naam van het voorstel'
              required
              fullWidth
              value={proposalTitle}
              onChange={(ev) => setProposalTitle(ev.target.value)}
              variant='outlined'
              sx={{
                '& fieldset': { border: 'none' }
              }}
            />
            <FormControl fullWidth>
              <Autocomplete
                popupIcon={<ExpandMoreIcon />}
                filterOptions={(a) => a}
                loading={isFetchingAccounts}
                onInputChange={(ev, val, reason) => {
                  if (reason === 'clear' || reason === 'reset') {
                    setSearchTerm('')
                  } else setSearchTerm(val)
                }}
                onChange={(_ev, val, reason) => selectedAccountOnChange(val, reason)}
                value={selectedAccount}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Klant'
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isFetchingAccounts
                            ? (
                              <CircularProgress
                                size={20}
                                sx={{
                                  color: 'var(--color-brand-quarternary-300)'
                                }}
                              />
                              )
                            : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
                id='customer-select'
                options={accounts}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                getOptionLabel={(a) => a.accountName}
                sx={{
                  '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                    color: 'var(--color-brand-quarternary-300)',
                    width: 24,
                    height: 24
                  }
                }}
              />
            </FormControl>
            <Autocomplete
              popupIcon={<ExpandMoreIcon />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Locatie'
                  required
                  InputProps={{
                    ...params.InputProps
                  }}
                />
              )}
              id='customer-site-select'
              options={selectedAccount != null ? (selectedAccount.customerSites ?? []) : []}
              value={selectedCustomerSite}
              onChange={(ev, val) => setSelectedCustomerSite(val)}
              getOptionLabel={(c) => c?.name ?? 'Geen naam'}
              noOptionsText='Geen locaties bekend'
              sx={{
                '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                  color: 'var(--color-brand-quarternary-300)',
                  width: 24,
                  height: 24
                }
              }}
            />
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='nl'>
              <DatePicker
                label='Kunstwerken reserveren tot'
                value={reserveUntil}
                disablePast
                maxDate={inThreeMonths}
                onChange={(newDate) => setReserveUntil(newDate)}
                onError={(err) => setReservationError(err)}
                slotProps={{
                  field: { clearable: true, onClear: () => setReserveUntil(null) },
                  openPickerButton: {
                    sx: {
                      color: 'var(--color-brand-quarternary-300)'
                    }
                  },
                  textField: {
                    helperText: reservationErrorMessage
                  }
                }}
              />
            </LocalizationProvider>
          </Box>
          <Divider
            sx={{
              color: 'rgba(0, 0, 0, 0.08)',
              marginTop: '24px'
            }}
          />
          <DialogActions>
            <Button
              variant='contained'
              color='neutral'
              onClick={closeDialog}
              sx={{
                bgcolor: 'var(--color-neutral-200)',
                '&.MuiButton-contained': {
                  border: '1px solid rgba(0,0,0,0.04)'
                }
              }}
            >
              Annuleren
            </Button>
            <Button variant='contained' type='submit'>
              {proposal != null ? 'Wijzigen' : 'Aanmaken'}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

import {
  InteractionRequiredAuthError,
  InteractionType
} from '@azure/msal-browser'
import {
  AuthenticatedTemplate,
  useMsal,
  useMsalAuthentication
} from '@azure/msal-react'
import { ErrorToast } from '@root/components/atoms/ErrorToast/Component'
import { useAppContext } from '@root/global/context'
import { errorToastHelper, msAuthScopes } from '@root/misc/helpers'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  children: React.ReactNode
}

export const ConsultantAuth = (props: Props): JSX.Element => {
  const { accounts } = useMsal()
  const { errors, setErrors } = useAppContext()
  const request = {
    scopes: [msAuthScopes.read, msAuthScopes.api],
    redirectUri: window.origin + '/blank.html'
  }
  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    request
  )
  const navigate = useNavigate()
  /* Handler for disposing of the ErrorToast modal. */
  const closeHandler = (key: string): void => {
    setErrors((old) => {
      return old.filter((err) => err.key !== key)
    })
  }

  React.useEffect(() => {
    if (accounts.length === 0) {
      navigate('/login')
    } else if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup, request)
        .then((_resp) => {
          console.log('succesfully logged in through popup')
        })
        .catch((_e) => {
          // logging in failed, need to show the error in a dialog or toast
          setErrors(
            errorToastHelper(
              errors,
              'Inloggen mislukt. Geef de juiste permissies of probeer opnieuw in te loggen. U wordt automatisch doorgeleid naar de inlogpagina.',
              8000
            )
          )
          setTimeout(() => {
            navigate('/login')
          }, 8000)
        })
    }
  }, [error])

  return (
    <>
      {errors.map((err, idx) => (
        <ErrorToast
          key={err.key}
          duration={err.duration}
          index={errors.length - idx - 1}
          onClose={() => closeHandler(err.key)}
        >
          {err.message}
        </ErrorToast>
      ))}
      <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>
    </>
  )
}

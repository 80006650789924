import Toll from '@mui/icons-material/Toll'
import { Box, Button, Divider, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { CloseSign, EuroSign, ImageSize, ShoppingCart, Tag } from '@root/components/atoms/Icons/Component'
import useWindowSize from '@root/misc/use-window-size'
import React from 'react'
import { imageUrl } from '@misc/image-utils'
import style from './style.module.css'
import { Displayable, Placement } from '@root/generated/graphql-request'
import { artSize, hasValue, infoValue } from '@root/misc/helpers'
import { ArtImage } from '@root/components/atoms/ArtImage/Component'

interface ArtObjectDetaiViewProps {
  /**
   * This contains all the data that will be displayed in the detail view.
   */
  placement: Placement
  /**
   * Callback function to be invoked when the detail view should be closed.
   */
  onClose: () => void
}

const availability = (item: boolean): JSX.Element =>
  item
    ? <Typography>Kunstwerk aankopen? Neem contact op voor de mogelijkheden.</Typography>
    : <Typography>Niet beschikbaar voor verkoop</Typography>

/** TODO: contains some hardcoded fields for the art object, which should be filled in when the API is ready. */
function ArtObjectInfo (props: Placement): JSX.Element {
  return (
    <div className={style['art-object-info']}>
      <div>
        <Typography variant='body1' sx={{ marginBlockEnd: 0, color: 'var(--color-neutral-600)' }}>{props.artObject.articleNumber}</Typography>
        <Typography variant='h1'>{props.artObject.displayable.mainArtist.artist}</Typography>
        <Typography variant='h3' sx={{ color: 'var(--color-neutral-500)' }}>{props.artObject.title}</Typography>
        <div className={style['art-extra-info']}>
          <Toll sx={{ fontSize: 16, color: 'var(--color-neutral-500)' }} />
          <Typography variant='body1'>{props.numberOfUnits}</Typography>
          <Typography variant='body1'>Deze is oud: {props.artObject.numberOfUnits}</Typography>
          <span className={style['fancy-dot']}>·</span>
          <div className={style.icon}><EuroSign height='16px' width='16px' /></div>
          <Typography variant='body1'>{Math.round(props.artObject.rentalValue)},-</Typography>
        </div>
      </div>

      <Divider className={style.divider} sx={{ marginTop: '16px', marginBottom: '16px' }} />
      <div className={style.metas}>
        <div className={style.meta}><div className={style.icon}><Tag height='16px' width='16px' /></div><Typography variant='body1'>{props.artObject.displayable.artObjectCategory?.category}</Typography></div>
        <div className={style.meta}><div className={style.icon}><ImageSize height='16px' width='16px' /></div><Typography variant='body1'>{artSize(props.artObject.displayable)}</Typography></div>

        <div className={style.meta} style={{ alignItems: 'flex-start' }}>
          <div className={style.icon} style={{ paddingTop: '4px' }}>
            <ShoppingCart height='16px' width='16px' />
          </div><Typography variant='body1'>{availability(props.artObject.displayable.isAvailableForSale)}</Typography>
        </div>
      </div>
    </div>
  )
}

interface ArtImageBoxProps {
  displayable: Displayable
}

const ArtImageBox = (props: ArtImageBoxProps): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => setOpen(false)

  return (
    <div>
      <div {...hasValue(props.displayable.mainImagePath) && { onClick: handleOpen }} className={style['art-object-thumbnail']}>
        <ArtImage src={props.displayable.mainImagePath} size='medium' alt={props.displayable.title} />
      </div>
      <Modal open={open} onClose={handleClose} aria-labelledby='kunstwerk volledige grootte'>
        <>
          <Box sx={{
            marginTop: '20px',
            marginLeft: '20px',
            fontSize: '20px',
            color: 'var(--color-brand-primary-100)'
          }}
          ><span className={style['modal-close-icon']} onClick={handleClose}><CloseSign width='14px' height='14px' /></span>
          </Box>

          <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 4
          }}
          >
            <img
              src={imageUrl(props.displayable.mainImagePath as string, 'large')}
              alt={props.displayable.title}
              className={style['lightbox-image']}
            />
          </Box>
        </>
      </Modal>

    </div>
  )
}

const listInfoValue = (values: any[] | null | undefined, key: string): string =>
  hasValue(values) ? values.map(v => v[key]).join(', ') : '-'

export default function ArtObjectDetailView (props: ArtObjectDetaiViewProps): JSX.Element {
  const windowSize = useWindowSize()
  const specifications = [
    { type: 'header', name: 'Lijst' },
    { type: 'info', name: 'Type lijst', value: infoValue(props.placement.artObject.frameType?.frameType, '-') },
    { type: 'info', name: 'Materiaal lijst', value: infoValue(props.placement.artObject.frameMaterial?.material, '-') },
    { type: 'info', name: 'Kleur lijst', value: infoValue(props.placement.artObject.frameColor?.color, '-') },
    { type: 'info', name: 'Breedte van de lijstrand', value: infoValue(props.placement.artObject.frameBorderWidth, '-') },
    { type: 'info', name: 'Materiaal van cover', value: infoValue(props.placement.artObject.coverMaterial?.material, '-') },
    { type: 'header', name: 'Kleur' },
    { type: 'info', name: 'Hoofdkleur', value: infoValue(props.placement.artObject.displayable.mainColor?.color, '-') },
    { type: 'info', name: 'Subkleuren', value: listInfoValue(props.placement.artObject.displayable.subColors, 'color') },
    { type: 'info', name: 'Kleurfamilie', value: infoValue(props.placement.artObject.displayable.colorFamily?.colorFamily, '-') },
    { type: 'header', name: 'Stijl' },
    { type: 'info', name: 'Hoofdstijl', value: infoValue(props.placement.artObject.displayable.mainStyle?.style, '-') },
    { type: 'info', name: 'Substijlen', value: listInfoValue(props.placement.artObject.displayable.subStyles, 'style') },
    { type: 'header', name: 'Thema' },
    { type: 'info', name: 'Hoofdthema', value: infoValue(props.placement.artObject.displayable.mainStyle?.style, '-') },
    { type: 'info', name: 'Subthemas', value: listInfoValue(props.placement.artObject.displayable.subStyles, 'style') },
    { type: 'header', name: 'Techniek' },
    { type: 'info', name: 'Categorie', value: infoValue(props.placement.artObject.displayable.artObjectCategory?.category, '-') },
    { type: 'info', name: 'Hoofdtechniek', value: infoValue(props.placement.artObject.displayable.mainTechnique?.technique, '-') },
    { type: 'info', name: 'Additionele techniek', value: infoValue(props.placement.artObject.displayable.additionalTechnique?.technique, '-') },
    { type: 'info', name: 'Subtechniek', value: infoValue(props.placement.artObject.displayable.subTechnique?.technique, '-') },
    { type: 'header', name: 'Materiaal' },
    { type: 'info', name: 'Hoofdmateriaal', value: infoValue(props.placement.artObject.displayable.mainMaterial?.material, '-') },
    { type: 'info', name: 'Andere materialen', value: listInfoValue(props.placement.artObject.displayable.otherMaterials, 'material') },
    { type: 'info', name: 'Materiaal beelddrager', value: infoValue(props.placement.artObject.displayable.paintingSurfaceMaterial?.material, '-') }
  ]

  return (
    <div className={style['art-object-detail-view']}>
      <Button onClick={props.onClose} className={style['close-button']}><CloseSign height='14px' width='14px' /> <Typography variant='button'>Sluit</Typography></Button>
      {
        windowSize.isTabletAndAbove
          ? (
            <div className={style['art-object-detail-container']}>
              <ArtObjectInfo {...props.placement} />
              <ArtImageBox displayable={props.placement.artObject.displayable} />
            </div>
            )
          : (
            <div className={style['art-object-detail-container']}>
              <ArtObjectInfo {...props.placement} />
              <ArtImageBox displayable={props.placement.artObject.displayable} />
            </div>
            )
      }

      <div className={style['art-object-specifications']}>
        <Typography variant='h3' sx={{ marginBlockEnd: '16px' }}>Specificaties</Typography>
        <TableContainer>
          <Table aria-label='specificaties tabel'>
            {specifications.map((spec) => (spec.type === 'header')
              ? (
                <TableHead>
                  <TableRow sx={{ backgroundColor: 'var(--color-brand-primary-100)' }}>
                    <TableCell>{spec.name}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                )
              : (
                <TableBody>
                  <TableRow key={spec.name}>
                    <TableCell component='th'>{spec.name}</TableCell>
                    <TableCell>{spec.value}</TableCell>
                  </TableRow>
                </TableBody>
                ))}
          </Table>
        </TableContainer>

      </div>
    </div>
  )
}

import { useMsal } from '@azure/msal-react'
import { Box, Typography } from '@mui/material'
import style from './style.module.css'
import { ProposalHeader } from '@root/components/molecules/ProposalHeader/Component'
import { ConsultantNavBar } from '@root/components/molecules/ConsultantNavBar/Component'
import { SegmentedController } from '@root/components/molecules/SegmentedController/Component'
import React from 'react'
import { Grid } from '@root/components/atoms/Grid/Component'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getProposal as getProposalMs } from '@root/services/microsoftAuthService'
import { Displayable, Proposal, ProposalLine } from '@root/generated/graphql-request'
import {
  artist,
  customerArtLocation,
  filterMaybes,
  defineFilterProposalLine,
  groupBy,
  isProposalLine,
  hasValue
} from '@root/misc/helpers'
import {
  ProposalLineDisplayableCard,
  ProposalLineDisplayableCardState
} from '@root/components/molecules/ProposalLineDisplayableCard/Component'
import { Loader } from '@root/components/atoms/Loader/Component'
import { ActiveDisplayableProps, Viewer } from '@root/misc/types'
import { Slideshow } from '@root/components/molecules/Slideshow/Component'
import DisplayableDetailView from '@root/components/molecules/DisplayableDetailView/Component'
import { getProposal } from '@root/services/apiService'

interface ProposalLineSectionProps {
  title: string
  value: ProposalLine[]
  state: ProposalLineDisplayableCardState
  proposal: Proposal
  viewer: Viewer
  onProposalLinesChange: () => void
  activeDisplayableProps: ActiveDisplayableProps
}

interface ConsultantProposalPageProps {
  viewer: Viewer
}

const ProposalLineSection = (props: ProposalLineSectionProps) => {
  const [selectedProposalLine, setSelectedProposalLine] = React.useState<ProposalLine | null>(null)

  return (
    <>
      {selectedProposalLine !== null && (
        <DisplayableDetailView
          displayable={selectedProposalLine.displayable}
          onChange={() => props.onProposalLinesChange()}
          open={(props.activeDisplayableProps.activeDisplayabe != null) ? props.activeDisplayableProps.activeDisplayabe.id === selectedProposalLine.displayable.id : false}
          onClose={props.activeDisplayableProps.onActiveDisplayableClose}
          proposal={props.proposal}
          likedState={isProposalLine(selectedProposalLine.displayable, props.proposal.proposalLines)}
          viewer={props.state.viewer}
        />
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant='h3'>{props.title}</Typography>
        <div className={style['proposal-line-section-container']}>
          {props.value.map((pl) => (
            <ProposalLineDisplayableCard
              pl={pl}
              state={props.state}
              key={pl.id}
              onProposalLinesChange={props.onProposalLinesChange}
              proposal={props.proposal}
              displayable={pl.displayable}
              onSelectProposalLine={() => {
                setSelectedProposalLine(pl)
                props.activeDisplayableProps.setActiveDisplayable(pl.displayable)
              }}
            />
          ))}
        </div>
      </Box>
    </>
  )
}

/** A page viewable by both the Consultant and Client, that shows all the information about a proposal. */
export const ProposalDetailPage = ({ viewer }: ConsultantProposalPageProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { instance } = useMsal()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [proposal, setProposal] = React.useState<Proposal | null>()
  const [proposalLines, setProposalLines] = React.useState<Array<{ key: string, value: ProposalLine[] }>>([])
  const [tabValue, setTabValue] = React.useState<number>(0)
  const [currentFilter, setCurrentFilter] = React.useState<'customerArtLocation' | 'artist'>('customerArtLocation')
  const [activeDisplayable, setActiveDisplayable] = React.useState<Displayable | null>(null)

  const { id } = useParams()

  const getAndSetProposalData: () => Promise<void> = React.useCallback(async () => {
    if (viewer === 'consultant') {
      const proposal = await getProposalMs(id!, instance)
      const pls = filterMaybes(proposal?.proposalLines)
      setProposal(proposal)
      setProposalLines(groupBy(pls, defineFilterProposalLine(currentFilter)))
    } else {
      const proposal = await getProposal(id!)
      const pls = filterMaybes(proposal?.proposalLines)
      setProposal(proposal)
      setProposalLines(groupBy(pls, defineFilterProposalLine(currentFilter)))
    }
  }, [proposalLines, currentFilter])

  /** Helpers for managing and closing drawers `DisplayableDetailView` in a Proposal.  */
  function handleCloseDisplayable (): void {
    if (hasValue(proposal)) {
      navigate('/voorstellen/' + proposal.id + (viewer === 'consultant' ? '/consultant-overzicht' : '/overzicht'))
      setActiveDisplayable(null)
    }
  }
  function handleActiveDisplayable (disp: Displayable): void {
    if (hasValue(proposal)) {
      navigate('/voorstellen/' + proposal.id + (viewer === 'consultant' ? '/consultant-overzicht' : '/overzicht') + '?displayable=' + disp.id)
      setActiveDisplayable(disp)
    }
  }

  // Fetch proposal
  React.useEffect(() => {
    instance.initialize().then((_) => {
      getAndSetProposalData().then(() => {
        setIsLoading(false)
      })
    })
  }, [])

  React.useEffect(() => {
    if (tabValue === 0) {
      setCurrentFilter('customerArtLocation')
      setProposalLines(groupBy(filterMaybes(proposal?.proposalLines), customerArtLocation))
    } else {
      setCurrentFilter('artist')
      setProposalLines(groupBy(filterMaybes(proposal?.proposalLines), artist))
    }
  }, [tabValue])

  /** This useEffect here is to align the current filter and the filtered proposals. */
  React.useEffect(() => {
    if (currentFilter === 'customerArtLocation') {
    } else {
      setProposalLines(groupBy(filterMaybes(proposal?.proposalLines), artist))
    }
  }, [currentFilter])

  React.useEffect(() => {
    const displayableId = new URLSearchParams(window.location.search).get('displayable')
    if (!displayableId && hasValue(activeDisplayable)) {
      handleCloseDisplayable()
    }

    if (hasValue(displayableId) && hasValue(proposal) && !hasValue(activeDisplayable)) {
      const item = proposal.proposalLines?.find(pl => hasValue(pl) && pl.id === displayableId)
      if (hasValue(item)) {
        setActiveDisplayable(item.displayable)
      }
    }
  }, [location, proposal])

  return (
    <>
      {isLoading && proposal == null
        ? (
          <Loader />
          )
        : (
          <div className={viewer === 'consultant' ? 'page-wrapper' : ''}>
            {viewer === 'consultant' ? <ConsultantNavBar instance={instance} /> : <></>}
            <ProposalHeader
              state={{
                kind: 'proposal-detail',
                props: { proposal: proposal!, state: viewer, onSecretLinkShare: getAndSetProposalData }
              }}
            />
            <div className={style['page-content']}>
              <Grid>
                {viewer === 'client' && proposal?.proposalLines?.length! > 0 && (
                  <Slideshow pls={filterMaybes(proposal?.proposalLines)} />
                )}
                <div
                  className={style.title}
                  style={{
                    gridColumn: '2 / -2'
                  }}
                >
                  <Typography variant='h2'>Introductie</Typography>
                </div>

                <Typography
                  variant='body1'
                  style={{
                    gridColumn: '2 / -2'
                  }}
                >
                  {proposal?.introduction}
                </Typography>

                <Typography
                  variant='h2'
                  style={{
                    gridColumn: '2 / -2'
                  }}
                >
                  Geselecteerde werken
                </Typography>

                <div className={style['content-box']}>
                  <SegmentedController
                    ariaLabel='Collectiekeuze'
                    componentId='collection_segmented_controller'
                    value={tabValue}
                    setValue={setTabValue}
                    tabs={[
                      {
                        label: 'Per ruimte',
                        component: (
                          <>
                            {proposalLines.map((p) => (
                              <React.Fragment key={p.key}>
                                <ProposalLineSection
                                  title={p.key}
                                  value={p.value}
                                  state={{
                                    viewer,
                                    filter: currentFilter
                                  }}
                                  proposal={proposal!}
                                  viewer={viewer}
                                  onProposalLinesChange={() => {
                                    getAndSetProposalData()
                                  }}

                                  activeDisplayableProps={{
                                    activeDisplayabe: activeDisplayable,
                                    onActiveDisplayableClose: handleCloseDisplayable,
                                    setActiveDisplayable: handleActiveDisplayable
                                  }}
                                />
                              </React.Fragment>
                            ))}
                          </>
                        )
                      },
                      {
                        label: 'Per kunstenaar',
                        component: (
                          <>
                            {proposalLines.map((p) => (
                              <React.Fragment key={p.key}>
                                <ProposalLineSection
                                  title={p.key}
                                  value={p.value}
                                  state={{
                                    viewer,
                                    filter: currentFilter
                                  }}
                                  proposal={proposal!}
                                  viewer={viewer}
                                  onProposalLinesChange={() => {
                                    getAndSetProposalData()
                                  }}

                                  activeDisplayableProps={{
                                    activeDisplayabe: activeDisplayable,
                                    onActiveDisplayableClose: handleCloseDisplayable,
                                    setActiveDisplayable: handleActiveDisplayable
                                  }}
                                />
                              </React.Fragment>
                            ))}
                          </>
                        )
                      }
                    ]}
                  />
                </div>
              </Grid>
            </div>
          </div>
          )}
    </>
  )
}

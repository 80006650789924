import { useMsal } from '@azure/msal-react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Search from '@mui/icons-material/Search'
import { FormControlLabel, TablePagination, Tooltip, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import { CollapseIcon, FiltersClose } from '@root/components/atoms/Icons/Component'
import { Loader } from '@root/components/atoms/Loader/Component'
import { StandardSwitch } from '@root/components/atoms/StandardSwitch/Component'
import { ProposalHeader } from '@root/components/molecules/ProposalHeader/Component'
import { ConsultantNavBar } from '@root/components/molecules/ConsultantNavBar/Component'
import { DisplayableCard } from '@root/components/molecules/DisplayableCard/Component'
import { Filters } from '@root/components/molecules/DisplayableFiltersView/Component'
import { PageContent } from '@root/components/molecules/PageContent/Component'
import { ProposalInfo } from '@root/components/molecules/ProposalInfo/Component'
import { SidebarHandler } from '@root/components/molecules/SidebarHandler/Component'
import {
  Displayable,
  DisplayableFilterInput,
  DisplayableSortField,
  DisplayablesResult,
  Proposal,
  SortDirection
} from '@root/generated/graphql-request'
import { useAppContext } from '@root/global/context'
import { errorToastHelper, hasValue, isProposalLine } from '@root/misc/helpers'
import { CardStyle } from '@root/misc/types'
import { getDisplayablesForGrid, getProposal } from '@root/services/microsoftAuthService'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import style from './styles.module.css'

const SortingOptions = {
  artistDesc: {
    field: DisplayableSortField.MainArtistName,
    direction: SortDirection.Asc
  },
  dateAsc: {
    field: DisplayableSortField.DateInCollection,
    direction: SortDirection.Asc
  },
  dateDesc: {
    field: DisplayableSortField.DateInCollection,
    direction: SortDirection.Desc
  },
  unitsDesc: {
    field: DisplayableSortField.NumberOfUnits,
    direction: SortDirection.Desc
  },
  unitsAsc: {
    field: DisplayableSortField.NumberOfUnits,
    direction: SortDirection.Asc
  },
  variedAsc: {
    field: DisplayableSortField.Varied,
    direction: SortDirection.Asc
  }
}

interface QuickActionsProps {
  searchTerm: string
  onSearch: (term: string) => void
  onSort: (sorting: keyof typeof SortingOptions) => void
  sorting: keyof typeof SortingOptions
  setCardStyle: React.Dispatch<React.SetStateAction<CardStyle>>
}

const QuickActions = (props: QuickActionsProps) => {
  const [togglePresentationMode, setTogglePresentationMode] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (togglePresentationMode) props.setCardStyle('presentation')
    else props.setCardStyle('default')
  }, [togglePresentationMode])

  return (
    <div className='quick-actions-container'>
      <Box className={style['quick-action-list']}>
        <FormControlLabel
          control={<StandardSwitch checked={togglePresentationMode} onClick={() => setTogglePresentationMode(!togglePresentationMode)} />}
          label={
            <Typography variant='body1' sx={{ color: 'var(--color-neutral-500)' }}>
              Presentatiemodus
            </Typography>
          }
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginX: 0
          }}
        />

        <Select
          IconComponent={ExpandMoreIcon}
          value={props.sorting}
          variant='outlined'
          style={{ backgroundColor: '#fff', width: '200px', height: '40px' }}
          onChange={(ev) => props.onSort(ev.target.value as keyof typeof SortingOptions)}
        >
          <MenuItem value='artistDesc'>Kunstenaar A-Z</MenuItem>
          <MenuItem value='dateAsc'>Datum (oud - nieuw)</MenuItem>
          <MenuItem value='dateDesc'>Datum (nieuw - oud)</MenuItem>
          <MenuItem value='unitsDesc'>Eenheden (hoog - laag)</MenuItem>
          <MenuItem value='unitsAsc'>Eenheden (laag - hoog)</MenuItem>
          <MenuItem value='variedAsc'>Gevarieerd</MenuItem>
        </Select>
        <OutlinedInput
          placeholder='Zoek kunstenaar, titel of artikelnummer'
          size='small'
          value={props.searchTerm}
          onChange={(ev) => props.onSearch(ev.target.value)}
          startAdornment={
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          }
          style={{ backgroundColor: '#fff', width: '360px' }}
        />
      </Box>
    </div>
  )
}

export const ProposalOverviewPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true)
  const [filtersOpen, setFiltersOpen] = React.useState<boolean>(false)
  const [proposalInfoOpen, setProposalInfoOpen] = React.useState<boolean>(true)
  const [isGridLoading, setIsGridLoading] = React.useState<boolean>(true)
  const [isLoadingProposal, setIsLoadingProposal] = React.useState<boolean>(false)
  const { instance } = useMsal()
  const { id } = useParams()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(100)
  const [totalCount, setTotalCount] = React.useState<number>(0)
  const [activeDisplayable, setActiveDisplayable] = React.useState<Displayable | null>(null)

  const [sorting, setSorting] = React.useState<keyof typeof SortingOptions>('variedAsc')

  const [searchTerm, setSearchTerm] = React.useState<string>('')

  const [proposal, setProposal] = React.useState<Proposal>()
  const [displayables, setDisplayables] = React.useState<Displayable[]>([])
  const [selectedFilters, setSelectedFilters] = React.useState<DisplayableFilterInput | null>(null)

  /** Used to reset the left filterbar by passing it an unique `key` prop. */
  const [filterKey, setFilterKey] = React.useState<number>(0)

  const mostRecentDisplayablesRequest = React.useRef<Promise<any> | null>(null)

  const [cardStyle, setCardStyle] = React.useState<CardStyle>('default')

  const { errors, setErrors } = useAppContext()

  const getAndSetProposalData = React.useCallback(async () => {
    const proposal = await getProposal(id!, instance)
    setProposal(proposal)
  }, [])

  const fetchInitialData = React.useCallback(async () => {
    const proposal = await getAndSetProposalData()

    return proposal
  }, [])

  const setDisplayablesData = React.useCallback((ds: DisplayablesResult) => {
    setDisplayables(ds.items as Displayable[])
    setTotalCount(ds.totalCount)
  }, [])

  React.useEffect(() => {
    if (!isFirstRender) {
      setIsGridLoading(true)
      const newSorting = SortingOptions[sorting]

      getDisplayablesForGrid(
        {
          pagination: { limit: rowsPerPage, offset: page * rowsPerPage },
          filter: {
            searchTerm,
            ...(selectedFilters != null && selectedFilters)
          },
          sorting: newSorting
        },
        instance
      )
        .then((ds) => {
          setDisplayablesData(ds as DisplayablesResult)
          setIsGridLoading(false)
          window.scrollTo({ top: 0 })
        })
        .catch((err) => {
          setErrors(
            errorToastHelper(
              errors,
              'Het aanpassen van de pagina is mislukt. Ververs de pagina of neem contact op met de beheerder.'
            )
          )
        })
    }
  }, [page])

  React.useEffect(() => {
    if (!isFirstRender) {
      setIsGridLoading(true)
      setRowsPerPage(rowsPerPage)
      setPage(0)
      const newSorting = SortingOptions[sorting]

      getDisplayablesForGrid(
        {
          pagination: { limit: rowsPerPage, offset: page * rowsPerPage },
          filter: {
            searchTerm,
            ...(selectedFilters != null && selectedFilters)
          },
          sorting: newSorting
        },
        instance
      )
        .then((ds) => {
          setDisplayablesData(ds as DisplayablesResult)
          setIsGridLoading(false)
        })
        .catch((err) => {
          setErrors(
            errorToastHelper(
              errors,
              'Het veranderen van de pagina is mislukt. Ververs de pagina of neem contact op met de beheerder.'
            )
          )
        })
    }
  }, [rowsPerPage])

  React.useEffect(() => {
    if (!isFirstRender) {
      setIsGridLoading(true)
      const controller = new AbortController()

      const signal = controller.signal

      const debounceFn = setTimeout(() => {
        const newSorting = SortingOptions[sorting]
        getDisplayablesForGrid(
          {
            pagination: { limit: rowsPerPage, offset: page * rowsPerPage },
            filter: {
              searchTerm,
              ...(selectedFilters != null && selectedFilters)
            },
            sorting: newSorting
          },
          instance,
          { signal }
        )
          .then((resp) => {
            setDisplayablesData(resp as DisplayablesResult)
            setIsGridLoading(false)
          })
          .catch((error) => {
            if (error.name === 'AbortError') {
              // We aborted the request with `controller` here. No need to show a toast.
            } else {
              setErrors(
                errorToastHelper(
                  errors,
                  'De zoekopdracht is mislukt. Ververs de pagina of neem contact op met de beheerder.'
                )
              )
            }
          })
      }, 250)

      return () => {
        controller.abort()
        clearTimeout(debounceFn)
      }
    }
  }, [searchTerm])

  React.useEffect(() => {
    if (!isFirstRender) {
      setIsGridLoading(true)

      const newSorting = SortingOptions[sorting]

      getDisplayablesForGrid(
        {
          pagination: { limit: rowsPerPage, offset: page * rowsPerPage },
          filter: {
            searchTerm,
            ...(selectedFilters != null && selectedFilters)
          },
          sorting: newSorting
        },
        instance
      )
        .then((resp) => {
          setDisplayables(resp.items as Displayable[])
          setTotalCount(resp.totalCount)
          setIsGridLoading(false)
        })
        .catch((err) => {
          setErrors(
            errorToastHelper(errors, 'Het sorteren is mislukt. Ververs de pagina of neem contact op met de beheerder.')
          )
        })
    }
  }, [sorting])

  React.useEffect(() => {
    setIsGridLoading(true)
    if (!isFirstRender && (selectedFilters ?? {}).hasOwnProperty('height')) {
      const debounceFn = setTimeout(() => {
        const newSorting = SortingOptions[sorting]
        const currentDisplayablesRequest = getDisplayablesForGrid(
          {
            pagination: { limit: rowsPerPage, offset: page * rowsPerPage },
            filter: { searchTerm, ...selectedFilters },
            sorting: newSorting
          },
          instance
        ).then((resp) => resp)

        mostRecentDisplayablesRequest.current = currentDisplayablesRequest

        currentDisplayablesRequest
          .then((resp) => {
            if (currentDisplayablesRequest === mostRecentDisplayablesRequest.current) {
              setDisplayables(resp.items as Displayable[])
              setTotalCount(resp.totalCount)
              setIsGridLoading(false)
            }
          })
          .catch((err) => {
            setErrors(
              errorToastHelper(
                errors,
                'Filters ophalen is mislukt. Ververs de pagina of neem contact op met de beheerder.'
              )
            )
          })
      }, 250)
      return () => clearTimeout(debounceFn)
    }
  }, [selectedFilters])

  // Filters
  const dynamicFilterOnChange = (filters: any): void => {
    setPage(0)
    setSelectedFilters(filters as DisplayableFilterInput)
  }

  // Collapse the sidebars when presentation mode is active
  React.useEffect(() => {
    if (cardStyle === 'presentation' && filtersOpen) {
      setFiltersOpen(false)
    }
    if (cardStyle === 'presentation' && proposalInfoOpen) {
      setProposalInfoOpen(false)
    }
  }, [cardStyle])

  // Initial fetch for Proposal data and Displayables
  React.useEffect(() => {
    const checkLogin = async () => {
      await instance.initialize()
      const currentAccounts = instance.getAllAccounts()
      if (currentAccounts.length === 0) return navigate('/login')
      else instance.setActiveAccount(currentAccounts[0])
    }
    checkLogin().then(() => {
      fetchInitialData().then(() => {
        setLoading(false)
        setIsGridLoading(false)
      })

      setIsFirstRender(false)
    })
  }, [])

  /** Helpers for managing and closing drawers `DisplayableDetailView` in a Proposal.  */
  function handleCloseDisplayable (): void {
    if (hasValue(proposal)) {
      navigate('/voorstellen/' + proposal.id)
      setActiveDisplayable(null)
    }
  }
  function handleActiveDisplayable (disp: Displayable): void {
    if (hasValue(proposal)) {
      navigate('/voorstellen/' + proposal.id + '?displayable=' + disp.id)
      setActiveDisplayable(disp)
    }
  }

  React.useEffect(() => {
    const displayableId = new URLSearchParams(window.location.search).get('displayable')
    if (!displayableId && hasValue(activeDisplayable)) {
      handleCloseDisplayable()
    }

    if (hasValue(displayableId) && hasValue(displayables) && displayables.length > 0 && !hasValue(activeDisplayable)) {
      const item = displayables.find(d => hasValue(d) && d.id === displayableId)
      if (hasValue(item)) {
        setActiveDisplayable(item)
      }
    }
  }, [location, displayables])

  if (loading && proposal == null) {
    return <Loader />
  }
  return (
    <div className='page-wrapper'>
      <ConsultantNavBar instance={instance} />
      <ProposalHeader
        state={{
          kind: 'proposal-overview',
          props: {
            proposal: proposal!
          }
        }}
      />
      <Box
        marginTop='28px'
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            width: '256px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button className={style['filter-button']} onClick={() => setFiltersOpen(!filtersOpen)}>
            <CollapseIcon open={filtersOpen} /> Filters
          </Button>

          {filtersOpen &&
            selectedFilters != null &&
            Object.values(selectedFilters).filter((f) => f !== undefined).length !== 0
            ? (
              <Tooltip title='Herstel alle filters'>
                <Button
                  color='primary'
                  onClick={() => {
                    setSelectedFilters({})
                    setFilterKey((old) => old + 1)
                  }}
                >
                  <FiltersClose />
                </Button>
              </Tooltip>
              )
            : (
              <></>
              )}
        </Box>
        <Button className={style['filter-button']} onClick={() => setProposalInfoOpen(!proposalInfoOpen)}>
          <CollapseIcon open={!proposalInfoOpen} /> Collectievoorstel
        </Button>
      </Box>
      <SidebarHandler
        startSideBar={
          <Filters
            toggleOpenCallback={setFiltersOpen}
            onFilterChange={dynamicFilterOnChange}
            results={500}
            key={filterKey}
          />
        }
        startSideBarOpen={filtersOpen}
        startSideBarWidth={256}
        setStartSideBarOpen={setFiltersOpen}
        endSideBar={
          <ProposalInfo
            proposal={proposal!}
            instance={instance}
            proposalLines={proposal?.proposalLines ?? []}
            onProposalLinesChange={() => {
              setIsLoadingProposal(true)
              getAndSetProposalData()
              setIsLoadingProposal(false)
            }}
            isLoadingProposal={isLoadingProposal}
            activeDisplayableProps={{
              activeDisplayabe: activeDisplayable,
              onActiveDisplayableClose: handleCloseDisplayable,
              setActiveDisplayable: handleActiveDisplayable
            }}
          />
        }
        endSideBarOpen={proposalInfoOpen}
        setEndSideBarOpen={setProposalInfoOpen}
        endSideBarWidth={424}
        isLargeDesktopBreakpoint={1020}
      >
        <PageContent
          title='Alle werken'
          titleStyle={{
            display: 'flex',
            flexWrap: 'wrap'
          }}
          endElement={
            <QuickActions
              searchTerm={searchTerm}
              onSearch={(term: string) => {
                setPage(0)
                setSearchTerm(term)
              }}
              onSort={(sorting: keyof typeof SortingOptions) => {
                setPage(0)
                setSorting(sorting)
              }}
              sorting={sorting}
              setCardStyle={setCardStyle}
            />
          }
        >
          {isGridLoading
            ? (
              <Loader />
              )
            : displayables.length === 0 && selectedFilters != null
              ? (
                <div className={style['no-results-container']}>
                  <Typography variant='h3' align='center'>
                    Geen kunstwerken gevonden
                  </Typography>
                  <Typography align='center'>
                    Er zijn geen kunstwerken gevonden voor deze zoekterm. Pas de zoekterm aan of probeer het kunstwerk te
                    vinden met de filters.
                  </Typography>
                </div>
                )
              : (
                <div className={style['custom-grid'] + ' ' + (cardStyle === 'presentation' ? style.presentation : '')}>

                  {displayables.map((d: Displayable) => (
                    <DisplayableCard
                      displayable={d}
                      proposal={proposal!}
                      key={d.id}
                      likedState={isProposalLine(d, proposal?.proposalLines)}
                      onChange={() => {
                        setIsLoadingProposal(true)
                        getAndSetProposalData()
                        setIsLoadingProposal(false)
                      }}
                      cardStyle={cardStyle}
                      activeDisplayableProps={{
                        activeDisplayabe: activeDisplayable,
                        onActiveDisplayableClose: handleCloseDisplayable,
                        setActiveDisplayable: handleActiveDisplayable
                      }}
                    />
                  ))}
                </div>
                )}
        </PageContent>
        {isGridLoading
          ? (
            <></>
            )
          : (
            <TablePagination
              component='div'
              count={totalCount}
              page={page}
              onPageChange={(_, page) => setPage(page)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(ev) => setRowsPerPage(parseInt(ev.target.value, 10))}
              rowsPerPageOptions={[10, 50, 100]}
              labelRowsPerPage='Rijen per pagina'
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count}`}
            />
            )}
      </SidebarHandler>
    </div>
  )
}

import { IPublicClientApplication } from '@azure/msal-browser'
import { logout } from '@root/services/apiService'
import { hasValue } from './helpers'

/** Magic 'always log me out of everything' function */
export async function logoutInstances (clientInstance: any, consultantInstance: IPublicClientApplication | null) {
  if (clientInstance || hasValue(localStorage.getItem('authStatus'))) {
    await logout()
  }

  if ((consultantInstance?.getActiveAccount()) != null) {
    await consultantInstance.logout({ postLogoutRedirectUri: window.origin + '/login' })
  }

  return null
}

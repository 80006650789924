import React from 'react'
import style from './styles.module.css'
import { ProposalHeader } from '@root/components/molecules/ProposalHeader/Component'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Search from '@mui/icons-material/Search'
import {
  Avatar,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import { PageContent } from '@root/components/molecules/PageContent/Component'
import { ProposalDialog } from '@root/components/atoms/ProposalDialog/Component'
import {
  getProposals,
  getProposalsPageData
} from '@root/services/microsoftAuthService'
import {
  Proposal,
  ProposalsResult,
  UserProfile
} from '@root/generated/graphql-request'
import { useMsal } from '@azure/msal-react'
import { Loader } from '@root/components/atoms/Loader/Component'
import { ProposalQuickMenu } from '@root/components/atoms/ProposalQuickMenu/Component'
import { errorToastHelper, formatDateTime } from '@root/misc/helpers'
import { useNavigate } from 'react-router-dom'
import { ConsultantNavBar } from '@root/components/molecules/ConsultantNavBar/Component'
import { useAppContext } from '@root/global/context'

interface QuickActionsProps {
  searchTerm: string
  onSearch: (term: string) => void
  /** Callback to be executed when a new proposal has been made. */
  onSuccess: (p: Proposal) => void
}

const QuickActions = (props: QuickActionsProps) => {
  return (
    <div className='quick-actions-container'>
      <Box className={style['quick-action-list']}>
        <OutlinedInput
          style={{ backgroundColor: '#fff', width: '100%', minWidth: '360px' }}
          placeholder='Zoek op titel of klantnaam'
          size='medium'
          value={props.searchTerm}
          onChange={(ev) => props.onSearch(ev.target.value)}
          startAdornment={
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          }
        />
        <ProposalDialog onSuccess={props.onSuccess}>
          <Button
            variant='contained'
            sx={{
              padding: '10px 16px 8px 16px',
              whiteSpace: 'nowrap',
              minWidth: 'auto'
            }}
          >
            Start nieuw voorstel
          </Button>
        </ProposalDialog>
      </Box>
    </div>
  )
}

export const ProposalsListPage = () => {
  const navigate = useNavigate()
  const [userProfile, setUserProfile] = React.useState<UserProfile>()
  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(100)
  const [searchTerm, setSearchTerm] = React.useState<string>('')

  const { errors, setErrors } = useAppContext()

  React.useEffect(() => {
    if (!isFirstRender) {
      getProposals(
        { pagination: { limit: rowsPerPage, offset: page * rowsPerPage } },
        instance
      )
        .then((resp) => {
          setProposalsData(resp.proposals as ProposalsResult)
        })
        .catch((_err) => {
          setErrors(
            errorToastHelper(
              errors,
              'Het veranderen van de pagina is mislukt. Ververs de pagina of neem contact op met de beheerder.'
            )
          )
        })
    }
  }, [page])

  React.useEffect(() => {
    if (!isFirstRender) {
      setPage(0)
      getProposals(
        { pagination: { limit: rowsPerPage, offset: page * rowsPerPage } },
        instance
      )
        .then((resp) => {
          setProposalsData(resp.proposals as ProposalsResult)
        })
        .catch((_err) => {
          setErrors(
            errorToastHelper(
              errors,
              'Het veranderen van de pagina is mislukt. Ververs de pagina of neem contact op met de beheerder.'
            )
          )
        })
    }
  }, [rowsPerPage])

  const [proposalsList, setProposalsList] = React.useState<Proposal[]>([])
  const [totalCount, setTotalCount] = React.useState<number>(0)

  const { instance } = useMsal()

  const fetchProposals = React.useCallback(async () => {
    await instance.initialize()
    const { proposals, userProfile } = await getProposalsPageData(
      { limit: rowsPerPage, offset: page * rowsPerPage },
      instance
    )
    setProposalsList(proposals.items as Proposal[])
    setTotalCount(proposals.totalCount)
    setUserProfile(userProfile!)
  }, [])

  const setProposalsData = React.useCallback(
    (result: ProposalsResult) => {
      setProposalsList(result.items as Proposal[])
      setTotalCount(result.totalCount)
    },
    [proposalsList, totalCount]
  )

  const handleNewProposal = (proposal: Proposal) => {
    setProposalsList([proposal, ...proposalsList])
    setTotalCount(totalCount + 1)
  }

  const handleEditProposal = (proposal: Proposal) => {
    const newList = proposalsList.map((p) => {
      if (p.id === proposal.id) {
        return proposal
      } else return p
    })
    setProposalsList(newList)
  }

  React.useEffect(() => {
    if (!isFirstRender) {
      const controller = new AbortController()

      const signal = controller.signal

      const debounceFn = setTimeout(() => {
        getProposals(
          {
            filter: { searchTerm },
            pagination: { limit: rowsPerPage, offset: page * rowsPerPage }
          },
          instance,
          { signal }
        )
          .then((resp) => {
            setProposalsData(resp.proposals as ProposalsResult)
          })
          .catch((error) => {
            if (error.name === 'AbortError') {
              // We aborted the request with `controller` here. No need to show a toast.
            } else {
              setErrors(
                errorToastHelper(
                  errors,
                  'De zoekopdracht is mislukt. Ververs de pagina of neem contact op met de beheerder.'
                )
              )
            }
          })
      }, 500)

      return () => {
        controller.abort()
        clearTimeout(debounceFn)
      }
    }
  }, [searchTerm])

  // Initial fetch for Proposals
  React.useEffect(() => {
    fetchProposals().then(() => {
      setLoading(false)
      setIsFirstRender(false)
    })
  }, [fetchProposals])

  if (loading) {
    return <Loader />
  }

  return (
    <div className='page-wrapper'>
      <ConsultantNavBar instance={instance} />
      <ProposalHeader
        state={{ kind: 'proposals', props: { userProfile: userProfile! } }}
      />
      <PageContent
        title='Voorstellen'
        endElement={
          <QuickActions
            searchTerm={searchTerm}
            onSearch={(term: string) => {
              setPage(0)
              setSearchTerm(term)
            }}
            onSuccess={handleNewProposal}
          />
        }
      >
        <div className={style['proposals-table']}>
          <TableContainer>
            <Table sx={{ backgroundColor: '#FFFFFF' }}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Art Consultant</TableCell>
                  <TableCell>Klant</TableCell>
                  <TableCell>Titel voorstel</TableCell>
                  <TableCell>Aanmaakdatum</TableCell>
                  <TableCell>Wijzigingsdatum</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {proposalsList.map((proposal) => {
                  const handleClick = () => {
                    navigate(`/voorstellen/${proposal.id}`)
                  }

                  return (
                    <TableRow key={proposal.id}>
                      <TableCell
                        scope='row'
                        onClick={handleClick}
                        className={style['clickable-cell']}
                      >
                        <Avatar
                          src={proposal.createdBy.profileImage!}
                          sx={{ height: 32, width: 32, borderRadius: '4px' }}
                        />
                      </TableCell>
                      <TableCell
                        onClick={handleClick}
                        className={style['clickable-cell']}
                      >
                        {proposal.createdBy.fullName}
                      </TableCell>

                      <TableCell
                        onClick={handleClick}
                        className={style['clickable-cell']}
                      >
                        {proposal.customerSite.name}
                      </TableCell>
                      <TableCell
                        onClick={handleClick}
                        className={style['clickable-cell']}
                      >
                        {proposal.title}
                      </TableCell>
                      <TableCell
                        onClick={handleClick}
                        className={style['clickable-cell']}
                      >
                        {formatDateTime(proposal.createdAt)}
                      </TableCell>
                      <TableCell
                        onClick={handleClick}
                        className={style['clickable-cell']}
                      >
                        {formatDateTime(proposal.updatedAt)}
                      </TableCell>
                      <TableCell>
                        <ProposalQuickMenu
                          proposal={proposal}
                          onEditSuccess={handleEditProposal}
                          onDelete={() => {
                            setProposalsList(
                              proposalsList.filter((p) => p.id !== proposal.id)
                            )
                            setTotalCount(totalCount - 1)
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <TablePagination
              component='div'
              count={totalCount}
              page={page}
              onPageChange={(_, page) => setPage(page)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(ev) =>
                setRowsPerPage(parseInt(ev.target.value, 10))}
              rowsPerPageOptions={[10, 50, 100]}
              labelRowsPerPage='Rijen per pagina'
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} van ${count}`}
            />
          </TableContainer>
        </div>
      </PageContent>
    </div>
  )
}
